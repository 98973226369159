import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';

import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

import { useUpdatePassword } from 'api/apiHooks/authHooks';

import Button from 'components/BaseComponents/Button';
import TextField from 'components/BaseComponents/TextField';

import { validationSchema } from './resetTenantPassword.validation';
import { useStyles, StyledErrorMessage, FormWrapper } from './resetTenantPassword.style';

export default function ResetTenantPassword() {
  const classes = useStyles();
  const history = useHistory();
  const [mandatory, setMandatory] = useState(false);
  const location = useLocation();
  const { username, sentPassword } = location;

  const { refetch: updatePassword } = useUpdatePassword(
    () => {
      history.push('/');
    },
    (e) => {
      console.log(e);
    }
  );

  async function resetPasswordSubmit(values) {
    const { password } = values;
    updatePassword({
      data: { username, temporaryPassword: sentPassword, newPassword: password },
    });
  }

  function getErrorMessage(errors, touched) {
    for (const el in errors) {
      if (errors[el].includes('required') && touched[el]) {
        setMandatory(false);
        return <StyledErrorMessage>Please fill all mandatory fields</StyledErrorMessage>;
      }
      setMandatory(true);
    }
  }

  return (
    <Container maxWidth='xs'>
      <Typography component='h2' variant='h2' className={classes.titleSmall}>
        Create a new password
      </Typography>
      <Formik
        validateOnChange={false}
        initialValues={{
          password: '',
          confirm_password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await resetPasswordSubmit(values);
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormWrapper>
            <Form>
              <FormLabel component='legend'>New password (Min. of 6 characters)</FormLabel>
              <Field
                variant='outlined'
                margin='normal'
                fullWidth
                name='password'
                placeholder='New password'
                type='password'
                id='password'
                as={TextField}
                invalid={touched.password && errors.password}
              />
              <FormLabel component='legend'>Repeat the new password</FormLabel>
              <Field
                variant='outlined'
                margin='normal'
                fullWidth
                name='confirm_password'
                type='password'
                placeholder='Repeat the new password'
                id='confirm_password'
                as={TextField}
                invalid={touched.confirm_password && errors.confirm_password}
              />
              <Button
                variant='contained'
                type='submit'
                disabled={isSubmitting}
                style={{ marginBottom: '16px', marginTop: '24px' }}
              >
                Save
              </Button>
              <div className={classes.errorMessageWrapper}>
                {getErrorMessage(errors, touched)}
                {mandatory ? (
                  <>
                    <StyledErrorMessage>
                      <ErrorMessage name='sent_password' />
                    </StyledErrorMessage>
                    <StyledErrorMessage>
                      <ErrorMessage name='password' />
                    </StyledErrorMessage>
                    <StyledErrorMessage>
                      <ErrorMessage name='confirm_password' />
                    </StyledErrorMessage>
                  </>
                ) : null}
              </div>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      <div className={classes.onboardingLink}>
        Or go back to <Link onClick={() => history.push('/')}>Login page</Link>
      </div>
    </Container>
  );
}
