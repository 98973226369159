import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './button.style';
import theme from '../../theme';

export default ({
  children,
  wrapperClassName,
  version,
  loading,
  disabled,
  padding,
  className,
  border,
  icon,
  fullWidth,
  ...props
}) => {
  const borderType = !version ? '3px solid' : '1px solid';
  const borderColor = border === 'secondary' ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main;
  const background = ['secondary', 'white'].includes(version)
    ? 'rgba(0, 0, 0, 0)'
    : version === 'bgWhite'
    ? '#ffffff'
    : theme.palette.primary.main;
  const classes = useStyles({ borderType, borderColor, background, border, padding, version, fullWidth });

  return (
    <div className={`${classes.btnWrapper} ${wrapperClassName}`}>
      <Button
        {...props}
        className={className}
        classes={{ root: classes.root, disabled: classes.disabled, contained: classes.contained }}
        disabled={loading || disabled}
      >
        {icon && icon}
        {children}
      </Button>
      {loading && (
        <CircularProgress
          style={{
            width: '20px',
            height: '20px',
            marginLeft: 10,
            position: 'absolute',
            top: 'calc(50% - 10px)',
          }}
        />
      )}
    </div>
  );
};
