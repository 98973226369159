import React from 'react';

const useCheckContext = (Context, errorText) => {
  const context = React.useContext(Context);

  if (context === undefined) {
    throw new Error(errorText);
  }

  return context;
};

export default useCheckContext;
