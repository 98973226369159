import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './resetPassword.style';
import GetEmailForm from './components/GetEmailForm';
import ResetPasswordForm from './components/ResetPasswordForm';

export default function ResetPassword() {
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState('');

  const history = useHistory();
  const classes = useStyles();

  const titles = ['Enter your username.', 'Pick a new password.', 'Your temporary password has been sent!'];
  const subTitiles = [
    'Enter your credentials to receive a reset link.',
    'Confirm your password below.',
    'Check your email to get it.',
  ];

  const renderView = (step) => {
    switch (step) {
      case 0:
        return <GetEmailForm goNext={setStep} setUsername={setUsername} />;
      case 1:
        return <ResetPasswordForm username={username} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Container maxWidth='xs'>
        <Grid container>
          <Grid item xs={12}>
            <Typography component='h2' variant='h2' className={classes.titleSmall}>
              {titles[step]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component='p' variant='body1' className={classes.body1}>
              {subTitiles[step]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderView(step)}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.onboardingLink}>
              Go back to <Link onClick={() => history.push('/')}>Login page</Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
