import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Form, Formik } from 'formik';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

import Button from 'components/BaseComponents/Button';

import { validationSchema } from '../resetPassword.validation';
import { useStyles, StyledErrorMessage, SFeild } from '../resetPassword.style';

export default function ResetPasswordForm({ username: initialUserName }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const { username: urlUsername } = location;

  const [error, setError] = useState();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  async function forgotPasswordSubmit(values) {
    setLoading(true);
    const { code, confirm_password } = values;
    try {
      await Auth.forgotPasswordSubmit(username, code.trim(), confirm_password);
      history.push('/');
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!urlUsername && !initialUserName) {
      return;
    }
    setUsername(urlUsername || initialUserName);
  }, [urlUsername, initialUserName]);

  return (
    <>
      <Formik
        validateOnChange={false}
        initialValues={{ code: '', password: '', confirm_password: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await forgotPasswordSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className={classes.innerForm}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel component='legend'>Confirmation code</FormLabel>
              </Grid>

              <Grid item xs={12}>
                <SFeild
                  id='code'
                  name='code'
                  placeholder='Confirmation code'
                  errors={touched.code && errors.code}
                  invalid={touched.code && errors.code}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel component='legend'>New password (Min. of 6 characters)</FormLabel>
              </Grid>

              <Grid item xs={12}>
                <SFeild
                  id='password'
                  name='password'
                  type='password'
                  placeholder='New password'
                  errors={touched.password && errors.password}
                  invalid={touched.password && errors.password}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel component='legend'>Repeat the password</FormLabel>
              </Grid>

              <Grid item xs={12}>
                <SFeild
                  type='password'
                  id='confirm_password'
                  name='confirm_password'
                  placeholder='Repeat the password'
                  autoComplete='confirm_password'
                  errors={touched.confirm_password && errors.confirm_password}
                  invalid={touched.confirm_password && errors.confirm_password}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.btnWrapper}>
                  <Button type='submit' disabled={loading} loading={loading} className={classes.onboardingBtn}>
                    Reset
                  </Button>
                </div>
                {error && (error.code === 'CodeMismatchException' || error.code === 'LimitExceededException') && (
                  <div className={classes.smallErrorMessageWrapper}>
                    <StyledErrorMessage>{error.message}</StyledErrorMessage>
                  </div>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
