import React, { lazy } from 'react';
import { RoutesNames } from './RoutesNames';

import ResentTenantPassword from './components/AuthComponents/ResetTenantPassword';
import EmailConfirmation from './components/AuthComponents/EmailConfirmation';
import SignUp from './components/AuthComponents/SignUp';
import ResetPassword from './components/AuthComponents/ResetPassword';
import Login from './components/AuthComponents/Login';

const Intro = lazy(() => import('../Pages/Intro'));
const Profile = lazy(() => import('../Pages/Profile'));
const Properties = lazy(() => import('../Pages/Properties'));
const Contracts = lazy(() => import('../Pages/Contracts'));
const Maintainers = lazy(() => import('../Pages/Maintainers'));
const Maintainer = lazy(() => import('../Pages/Maintainer'));
const PropertyManagers = lazy(() => import('../Pages/PropertyManagers'));
const PropertyManager = lazy(() => import('../Pages/PropertyManager'));
const Violations = lazy(() => import('../Pages/Violations'));
const Support = lazy(() => import('../Pages/Support'));
const Contract = lazy(() => import('../Pages/Contract'));
const Applications = lazy(() => import('../Pages/Applications'));
const Policy = lazy(() => import('../Pages/Policy'));
const DefaultPolicy = lazy(() => import('../Pages/DefautlPolicy'));
const Property = lazy(() => import('../Pages/Property'));
const Onboarding = lazy(() => import('../Pages/Onboarding'));
const DocumentsAdd = lazy(() => import('../Pages/DocumentsAdd'));
const IntegrationSettings = lazy(() => import('../Pages/Settings/IntegrationSettings'));
const PaymentSettings = lazy(() => import('../Pages/Settings/PaymentSettings'));
const LandlordTable = lazy(() => import('../Pages/LandlordTable'));
const SuggestedTexts = lazy(() => import('../Pages/SuggestedTexts'));
const TenantTable = lazy(() => import('../Pages/TenantTable'));
const LandlordDetail = lazy(() => import('../Pages/Admin/Landlord/LandlordDetail'));
const TenantDetail = lazy(() => import('../Pages/TenantLandlordTable/TenantDetail'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const PolicyInput = lazy(() => import('../Pages/PolicyInput'));
const TenantPaymentSettings = lazy(() => import('../Pages/TenantPaymentSettings'));
const TenantContracts = lazy(() => import('../Pages/TenantContracts'));
const TenantContractView = lazy(() => import('../Pages/TenantContractView'));
const Curriculum = lazy(() => import('../Pages/Curriculum'));
const SubCurriculum = lazy(() => import('../Pages/Curriculum/SubCurriculum'));
const CurriculumDetail = lazy(() => import('../Pages/Curriculum/CurriculumDetail'));
const Certificate = lazy(() => import('../Pages/Certificate'));

export const allowedUserTypes = {
  landlord: 'landlord',
  tenant: 'tenant',
  admin: 'admin',
  propertyManager: 'propertyManager',
};

const { landlord, tenant, admin, propertyManager } = allowedUserTypes;

export const RoutesData = [
  {
    path: RoutesNames.Intro,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, admin],
    component: Intro,
  },
  {
    path: RoutesNames.Profile,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, admin, propertyManager],
    component: Profile,
  },
  {
    path: RoutesNames.Certificate,
    isPrivate: true,
    allowedUserTypes: [tenant, landlord, admin, propertyManager],
    component: Certificate,
  },
  {
    path: RoutesNames.IndividualProperty,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Property,
  },
  {
    path: RoutesNames.Properties,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Properties,
  },
  {
    path: RoutesNames.IndividualContract,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, propertyManager],
    component: Contract,
  },
  {
    path: RoutesNames.Contracts,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Contracts,
  },
  {
    path: RoutesNames.Maintainers,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Maintainers,
  },
  {
    path: RoutesNames.IndividualMaintainer,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Maintainer,
  },
  {
    path: RoutesNames.PropertyManagers,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, propertyManager],
    component: PropertyManagers,
  },
  {
    path: RoutesNames.PropertyManager,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: PropertyManager,
  },
  {
    path: RoutesNames.Violations,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, propertyManager],
    component: Violations,
  },
  {
    path: RoutesNames.Support,
    isPrivate: true,
    allowedUserTypes: [tenant, landlord, propertyManager],
    component: Support,
  },
  {
    path: RoutesNames.Requests,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, admin, propertyManager],
    component: Applications,
  },
  {
    path: RoutesNames.Curriculum,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, tenant, admin, propertyManager],
    component: Curriculum,
  },
  {
    path: RoutesNames.SubCurriculum,
    isPrivate: true,
    exact: true,
    allowedUserTypes: [landlord, tenant, admin, propertyManager],
    component: SubCurriculum,
  },
  {
    path: RoutesNames.CurriculumDetail,
    isPrivate: true,
    allowedUserTypes: [landlord, tenant, admin, propertyManager],
    component: CurriculumDetail,
  },
  {
    path: RoutesNames.Policies,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Policy,
  },
  {
    path: RoutesNames.DefaultPolices,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: DefaultPolicy,
  },
  {
    path: RoutesNames.Onboarding,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: Onboarding,
  },
  {
    path: RoutesNames.Payment,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: PaymentSettings,
  },
  {
    path: RoutesNames.Resman,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: IntegrationSettings,
  },
  {
    path: RoutesNames.Integrations,
    isPrivate: true,
    allowedUserTypes: [landlord],
    component: IntegrationSettings,
  },
  {
    path: RoutesNames.Documents,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: DocumentsAdd,
  },
  {
    path: RoutesNames.IndividualLandlord,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordDetail,
  },
  {
    path: RoutesNames.Landlords,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: LandlordTable,
  },
  {
    path: RoutesNames.SuggestedTexts,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: SuggestedTexts,
  },

  {
    path: RoutesNames.IndividualTenant,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: TenantDetail,
  },
  {
    path: RoutesNames.Tenants,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: TenantTable,
  },
  {
    path: RoutesNames.Dashboard,
    isPrivate: true,
    allowedUserTypes: [landlord, propertyManager],
    component: Dashboard,
  },
  {
    path: RoutesNames.TenantContractView,
    isPrivate: true,
    allowedUserTypes: [tenant],
    component: TenantContractView,
  },
  {
    path: RoutesNames.TenantContracts,
    isPrivate: true,
    allowedUserTypes: [tenant],
    component: TenantContracts,
  },
  {
    path: RoutesNames.TenantPaymentSettings,
    isPrivate: true,
    allowedUserTypes: [tenant],
    component: TenantPaymentSettings,
  },
  {
    path: RoutesNames.StatePolicy,
    isPrivate: true,
    allowedUserTypes: [admin],
    component: PolicyInput,
  },
  {
    path: RoutesNames.ResetPassword,
    exact: true,
    component: ResetPassword,
  },
  {
    path: RoutesNames.SignUp,
    exact: true,
    component: SignUp,
  },
  {
    path: RoutesNames.Confirmation,
    exact: true,
    component: EmailConfirmation,
  },
  {
    path: RoutesNames.ResentTenantPassword,
    exact: true,
    component: ResentTenantPassword,
  },
  {
    path: '/',
    exact: true,
    component: Login,
  },
];
