import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const RightIconWrapper = styled.div`
  margin-left: 8px;
  height: 24px;
  display: flex;
  align-items: center;
`;

export const LeftIconWrapper = styled.div`
  margin-right: 8px;
  height: 24px;
  display: flex;
  align-items: center;
`;

export const STypography = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ $color }) => $color || ''};
    font-weight: ${({ $fontWeight }) => $fontWeight || ''};
  }
`;
