import React from 'react';
import Badge from '@material-ui/core/Badge';

import useCanCreateContract from 'components/Hooks/useCanCreateContract';
import { completePaymentText } from 'enums/paymentEnum';
import { LightTooltip } from '../sidbar.style';

// ToDo rename textWrapper should be Tooltip
export const CustomTooltip = ({ route, badge, children }) => {
  const canCreateContract = useCanCreateContract();

  if (!canCreateContract && ['settings', 'contracts'].includes(route)) {
    return (
      <LightTooltip key='11' title={completePaymentText[route]} placement='top-start'>
        <Badge
          overlap='rectangular'
          badgeContent='!'
          classes={{
            badge,
          }}
        >
          {children}
        </Badge>
      </LightTooltip>
    );
  }
  return <>{children}</>;
};
