import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import camelCase from 'lodash/camelCase';

import { defaultPagePath } from '../utils/enums';
import Portal from './Portal';

const PublicRoute = ({ component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userType = camelCase(user?.attributes['custom:userType'].split(' ')[0]);
  let showIntro = user?.attributes['custom:showIntro'];
  if (user?.attributes['custom:showIntro'] === undefined) {
    showIntro = true;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Redirect to={defaultPagePath[userType][showIntro]} />
        ) : (
          <Portal>
            <Component {...props} />
          </Portal>
        )
      }
    />
  );
};

export default PublicRoute;
