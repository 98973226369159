import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './App';
import { PropertyEditProvider } from './contexts/PropertyEditContext';

// TODO why
import awsExports from './aws-exports.js';

Amplify.configure(awsExports);

// TODO  seperate routes--- files and add providers layer
ReactDOM.render(
  <PropertyEditProvider>
    <App />
  </PropertyEditProvider>,
  document.getElementById('root')
);
