import React from 'react';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LOGIN_TYPE } from 'enums/loginType';
import ModifiedRadio from 'components/BaseComponents/Radio';

import { useStyles } from './selectRegisterType.style';

export function SelectRegisterType({ onSelect, initialValue, label = '' }) {
  const classes = useStyles();

  return (
    <Grid container className={classes.registerTypeWrapper}>
      <Grid item xs={12} className={classes.labelRow}>
        <FormLabel component='legend'>{label}:</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <RadioGroup row value={initialValue} onChange={(_, newValue) => onSelect(newValue)}>
          <FormControlLabel value={LOGIN_TYPE.EMAIL} control={<ModifiedRadio />} label='Email' />

          <FormControlLabel value={LOGIN_TYPE.PHONE_NUMBER} control={<ModifiedRadio />} label='Phone number' />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
