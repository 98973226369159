import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = createTheme({
  secondaryTextColor: '#9E9E9E',
  backgroundColor: '#EFF3F5',
  disableColor: '#DADADA',
  disableTextColor: '#9C9C9C',
  errorColor: '#EA001B',
  successColor: '#68D656',
  missingColor: '#f6911a',
  primarycolor: '#0D0D0D',
  iconGray: '#5F5F5F',
  lightPink: '#FF8282',
  palette: {
    primary: {
      main: '#FCC425',
      hover: '#fca625',
    },
    secondary: {
      main: '#ffffff',
      dark: '#5F5F5F',
    },
    shape: {
      borderRadius: 8,
      borderRadiusMd: 24,
      buttonBorderRadius: 48,
    },
  },
  typography: {
    color: '#0d0d0d',
    fontFamily: ['Cabin'].join(','),
    secondaryFontFamily: 'aesthet-nova',
    h1: {
      fontSize: '40px',
      lineHeight: 1.3,
      marginBottom: '24px',
      '@media (max-width:768px)': {
        fontSize: '24px',
        lineHeight: 1.5,
      },
      '@media (min-width:768px) and (max-width: 1280px)': {
        fontSize: '32px',
        lineHeight: 1.5,
      },
    },
    h2: {
      fontSize: '32px',
      lineHeight: 1.5,

      marginBottom: '24px',
      '@media (max-width:960px)': {
        fontSize: '24px',
        marginBottom: '16px',
      },
      '@media (min-width:768px) and (max-width: 1280px)': {
        fontSize: '24px',
      },
    },
    h3: {
      fontSize: '18px',
      lineHeight: 1.5,

      marginBottom: '24px',
      '@media (max-width:768px)': {
        fontSize: '16px',
      },
      '@media (min-width:768px) and (max-width: 1280px)': {
        fontSize: '16px',
      },
    },
    h4: {
      fontSize: '20px',
      lineHeight: 1.5,
      fontFamily: 'aesthet-nova',
    },
    h5: {
      fontSize: '18px',
      lineHeight: 1.5,
    },
    body1: {
      fontSize: '16px',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '14px',
      lineHeight: 1.5,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: '#FCC425',
        },
      },
      input: {
        backgroundColor: '#ffffff',
        padding: '10.5px 14px',
        borderRadius: '8px',
      },
      multiline: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
      },
      notchedOutline: {
        borderColor: '#FCC425',
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: defaultTheme.zIndex.modal - 1,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    MuiDialog: {
      root: {
        '& .MuiBackdrop-root': {
          zIndex: 'unset',
        },
      },
    },
    MuiDrawer: {
      root: {
        '& .MuiBackdrop-root': {
          zIndex: 'unset',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#FCC425',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500,
        '&$focused': {
          color: '#0d0d0d',
        },
      },
      focused: {},
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -10,
        marginRight: 20,
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: 10,
      },
    },
    MuiStepper: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiTable: {
      root: {
        '& .MuiFormLabel-root': {
          color: '#9E9E9E',
          fontSize: 14,
        },
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: ' 1px 4px 8px rgba(0, 0, 0, 0.12)',
      },
    },
    MuiInput: {
      input: {
        color: '#0D0D0D',
      },
    },
    MuiSelect: {
      outlined: {
        padding: '8px 32px 8px 12px',
      },
    },
    MuiAvatar: {
      img: {
        background: '#bdbdbd',
        objectFit: 'cover',
        borderRadius: '50%',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
