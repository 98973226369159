import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import { Auth } from 'aws-amplify';

import Link from '@material-ui/core/Link';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from 'components/BaseComponents/TextField';
import Button from 'components/BaseComponents/Button';
import { RoutesNames } from 'routes/RoutesNames';

import { validationSchema } from './emailConfirmation.validation';
import { useStyles, StyledErrorMessage, StyledRequest, InputWrapper } from './emailConfirmation.style';

export default function EmailConfirmation() {
  const [confirmError, setConfirmError] = useState('');
  const [showError, setShowError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const {
    state: { username, resetPassword },
  } = location;
  const matches = useMediaQuery('(max-width:768px)');

  function getUSername() {
    const isNumber = !!Number(username);
    if (isNumber) {
      return `+${Number(username)}`;
    }
    return username;
  }

  async function confirmSignUp(values) {
    setLoading(true);
    const { code } = values;
    try {
      await Auth.confirmSignUp(getUSername(), code.trim());
      if (resetPassword) {
        history.push({
          pathname: RoutesNames.ResetPassword,
          username,
        });
      } else {
        history.push('/');
      }
      setLoading(false);
    } catch (e) {
      setTimeLeft(0);
      setConfirmError(e.message);
      setShowError(true);
      console.log('error confirming sign up', e);
      setLoading(false);
    }
  }

  async function resendConfirmationCode() {
    setLoading(true);
    try {
      setTimeLeft(60);
      setCodeSent(true);
      console.log('code resent successfully');
      setLoading(false);
      await Auth.resendSignUp(getUSername());
    } catch (err) {
      console.log('error resending code: ', err);
      setLoading(false);
    }
  }

  useEffect(() => {
    let timer;
    if (codeSent) {
      if (timeLeft) {
        timer = setTimeout(() => {
          setTimeLeft(timeLeft - 1);
        }, 1000);
      } else {
        setCodeSent(false);
        setShowError(false);
      }
    }
    return () => clearTimeout(timer);
  }, [timeLeft, codeSent]);

  return (
    <>
      <Container maxWidth='xs'>
        <Typography component='h2' variant='h2' className={classes.titleSmall}>
          Enter the code we’ve just sent.
        </Typography>
        <Typography component='p' variant='body1' className={classes.subtitleSmall}>
          Enter the verification code sent to you to login.
        </Typography>

        <Formik
          validateOnChange={false}
          initialValues={{ code: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await confirmSignUp(values);
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <FormLabel component='legend'>Code</FormLabel>
              <InputWrapper matches={matches}>
                <Field
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  name='code'
                  placeholder='Confirmation code'
                  id='code'
                  autoComplete='code'
                  as={TextField}
                  nomargin
                  invalid={touched.code && errors.code}
                />
              </InputWrapper>
              {!codeSent && (
                <Button onClick={resendConfirmationCode} className={classes.resend}>
                  Resend.
                </Button>
              )}
              <div className={classes.btnWrapper}>
                <Button variant='contained' type='submit' disabled={isSubmitting} className={classes.onboardingBtn}>
                  Confirm
                </Button>
                {loading ? <CircularProgress style={{ marginLeft: '8px', width: '20px', height: '20px' }} /> : null}
              </div>
              <StyledErrorMessage>{errors.code}</StyledErrorMessage>
            </Form>
          )}
        </Formik>
        <>
          <div className={classes.onboardingLink}>
            Or go back to{' '}
            <Link onClick={() => history.push(RoutesNames.SignUp)} data-testid='sign-up-btn'>
              Sign up
            </Link>
          </div>
        </>
        <StyledRequest>
          {confirmError && !codeSent && showError && <StyledErrorMessage>{confirmError}</StyledErrorMessage>}
          {codeSent && (
            <>
              <Typography component='p' variant='body1' className={classes.resendIn}>
                Resend in
              </Typography>
              <StyledErrorMessage>{timeLeft}</StyledErrorMessage>
              <Typography component='p' variant='body1' className={classes.linkDisplay}>
                seconds
              </Typography>
            </>
          )}
        </StyledRequest>
      </Container>
    </>
  );
}
