import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { LightTooltip, useStyles } from './avatarGroup.style';

const ModifiedAvatarGroup = ({ items, noToolTip, className, avatarClassName }) => {
  const classes = useStyles();
  return (
    <AvatarGroup className={[classes.avatarGroup, className].join(' ')} max={4} classes={{ avatar: avatarClassName }}>
      {items?.map((item, i) => {
        const strings = (item.fullName || item.name).toUpperCase().split(' ');
        const secondLetter = strings[1] ? strings[1][0] : '';
        const name = strings[0][0] && strings[0][0] + secondLetter;

        return (
          <LightTooltip key={i} title={noToolTip ? '' : item.fullName || item.name} placement='top-start'>
            <Avatar alt='' src={item.photoPreSignedUrl || item.photoUrl}>
              {item.photoPreSignedUrl || item.photoUrl ? null : name || 'N/A'}
            </Avatar>
          </LightTooltip>
        );
      })}
    </AvatarGroup>
  );
};

export default ModifiedAvatarGroup;
