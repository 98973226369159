export const applicationEndpoints = {
  APPLICATION: 'application',
  TEMPLATE: `template`,
  WITH_DOCUMENTS: `withDocuments`,
  DOCUMENTS: 'documents',
  CHANGELOG: 'changelog',
  STATUS: 'status',
  COMMENT: 'comment',
  DOWNLOAD: 'download',
  STATISTICS: 'statistics',
  RECORDS: 'records',
  HTML: 'html',
};

export const contractEndpoints = {
  CONTRACT: 'contract',
  CONTENT: 'content',
  POLICY: 'policy',
  MOVE: 'move',
  TENANTS: 'tenants',
  TERMINATE: 'terminate',
  CONTENT_SUGGESTION: 'contentSuggestion',
  METADATA: 'metadata',
  STATUS: 'status',
};

export const unitEndpoints = {
  RENTALS: 'rentals',
  UNITS: 'units',
  PAGE: 'page',
};

export const curriculumEndpoints = {
  COURSE: 'course',
  EXTENDED: 'extended',
  TRAINING: 'training',
};

export const subscribersEndpoints = {
  SUBSCRIBERS: 'subscribers',
};

export const maintainerEndpoints = {
  MAINTAINER: 'maintainer',
  INVITE: 'invite',
  RENTALS: 'rentals',
  TENANT_COMMUNICATION: 'tenant-communication',
};

export const paymentEndpoints = {
  PAYMENT_METHOD: 'paymentMethod',
  SUBSCRIPTION: 'subscription',
  TENANT_PAYS: 'tenantPays',
};

export const userEndpoints = {
  USER: 'user',
  STATUS: 'status',
  PHOTO_URL: 'photoUrl',
  FULL_NAME: 'name',
};

export const tenantEndpoints = {
  TENANT: 'tenant',
  ALL: 'all',
  NAME: 'name',
  ATTRIBUTES: 'attributes',
  INVITE: 'invite',
  HAVE_ANIMAL: 'have-animal',
};

export const rentalEndpoints = {
  RENTAL: 'rentals',
  METADATA: 'metadata',
  POLICY: 'policy',
};

export const complaintEndpoints = {
  COMPLAINT: 'complaint',
  COMMENT: 'comment',
  COMMENTS: 'comments',
};

export const notificationEndpoints = {
  NOTIFICATION: 'notification',
};

export const landlordEndpoints = {
  LANDLORD: 'landlord',
  PAYMENT: 'payment',
  HISTORY: 'history',
  UPCOMING: 'upcoming',
  COURSE: 'course',
};

export const supportedStates = {
  STATES: 'statePolicy',
  SUPPORTEDSTATES: 'supportedStates',
};

export const authEndpoints = {
  AUTH: 'auth',
  TEMPORARY_PASSWORD_CHANGE: 'temporaryPasswordChange',
  FORGOT_PASSWORD: 'forgotPassword',
  SIGN_UP: 'signup',
};

export const policyEndpoints = {
  POLICY: 'policy',
  DEFAULT_POLICIES: 'state-policies',
  CONTENT: 'content',
  HAS_POLICY: 'has-state-default-policy',
  STATE_POLICY: 'statePolicy',
  STATE_MAP: 'stateMap',
};

export const propertyManagerEndpoints = {
  PROPERTY_MANAGERS: 'property_managers',
  INVITATION: 'invitation',
  PROPERTIES: 'properties',
};
