import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import theme from 'components/theme';

export const useStyles = makeStyles((theme) => ({
  titleSmall: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
    marginBottom: '17px',
  },
  subtitleSmall: {
    fontWeight: 500,
    marginBottom: '24px',
  },
  linkDisplay: {
    display: 'inline-block',
  },
  resendIn: {
    display: 'inline-block',
    marginLeft: '6px',
  },
  resend: {
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: theme.primarycolor,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    '&.MuiButtonBase-root': {
      minWidth: '48px',
      backgroundColor: 'initial!important',
      border: 'none!important',
      padding: '0!important',
    },
    '& .MuiButton-label': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
    borderBottom: '1px solid',
    '& span.MuiButton-label': {
      lineHeight: '20px',
    },
  },
  onboardingLink: {
    marginBottom: theme.spacing(1),
    fontWeight: 800,
    '& a': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.primarycolor,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
  },
  onboardingBtn: {
    marginRight: '10px',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0 16px',
  },
  redirect: {
    marginLeft: '6px',
    display: 'inline',
  },
}));

export const StyledErrorMessage = styled.p`
  margin: 0 5px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  color: ${theme.errorColor};
`;

export const StyledRequest = styled.div`
  display: block;
`;

export const InputWrapper = styled.div`
  width: ${(props) => (props.matches ? '100%' : '330px')};
  display: block;
`;
