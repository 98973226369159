import React from 'react';

import { RightIconWrapper, LeftIconWrapper, STypography } from './textIcon.style';

export default function TextIcon(props) {
  const { Icon, mainColor, fontWeight, pointer, reverse, onClick, disable, ...rest } = props;
  const { text, style = {} } = props;
  const styles = { display: 'inline-flex' };

  const Text = () => (
    <STypography $color={mainColor} $fontWeight={fontWeight} component='p' variant='body1' {...rest}>
      {text}
    </STypography>
  );
  const Rendered = () =>
    reverse ? (
      <>
        <LeftIconWrapper>
          <Icon color={mainColor} />
        </LeftIconWrapper>
        <Text />
      </>
    ) : (
      <>
        <Text />
        <RightIconWrapper>
          <Icon color={mainColor} />
        </RightIconWrapper>
      </>
    );

  return (
    <div
      onClick={!disable ? onClick : null}
      {...rest}
      style={{ ...styles, ...style, ...(disable && { cursor: 'default' }), ...(pointer && { cursor: 'pointer' }) }}
    >
      <Rendered />
    </div>
  );
}
