import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import get from 'lodash/get';

import AvatarGroup from 'components/BaseComponents/AvatarGroup';
import useApi from 'components/Hooks/useAPI';
import useIntersection from 'components/Hooks/useIntersection';
import { getFormattedTime } from 'components/utils';
import { useAppState } from 'contexts/PropertyEditContext';
import { useUpdateNotificationsStatus, useUpdateSingleNotificationStatus } from 'api/apiHooks/notificationHooks';
import { userRoles } from 'utils/enums';
import { RoutesNames } from '../../RoutesNames';
import { getPathname } from './helpers';
import { useStyles } from './notifications.style';

// TODO REFACTOR
export default function Notifications({
  userImpersonate,
  handleRedirection,
  getRedirectionPath,
  notificationsData,
  fetchNotifications,
  handleClose,
  loadMore,
  setMore,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { pagination } = useApi();
  const { profile, impersonate } = useAppState();
  const elementRef = useRef(null);
  let isIntersecting = useIntersection(elementRef);

  const [content, setContent] = useState([]);
  const isTenant = profile?.type === userRoles.TENANT && !impersonate;
  const isAdmin = profile?.type === userRoles.ADMIN || !!impersonate;

  const { refetch: updateNotificationsStatus } = useUpdateNotificationsStatus(() => {
    setMore(true);
    fetchNotifications();
  });

  const handleAdminNotificationClick = (id, data, pathname) => {
    const { metadata } = data;
    const { LANDLORD_ID: userId, LANDLORD_NAME: fullName } = metadata;

    if (!impersonate || userId !== profile.id.toString()) {
      userImpersonate({
        id: userId,
        fullName,
        type: userRoles.LANDLORD,
      });
      getRedirectionPath({ pathname, id });
    } else {
      handleRedirection({ pathname, id });
    }
  };

  const handleNotificationClick = (data) => {
    const { category, metadata } = data;
    const { APPLICATION_ID, COMPLAINT_ID, CONTRACT_ID } = metadata || {};
    const id = isAdmin ? APPLICATION_ID : COMPLAINT_ID || APPLICATION_ID || CONTRACT_ID;
    const pathname = getPathname(category);

    if (!metadata) {
      return;
    }

    switch (`/${pathname}`) {
      case RoutesNames.Contracts:
        history.push(`${isTenant ? RoutesNames.TenantContracts : RoutesNames.Contracts}/${id}`);
        return;
      case RoutesNames.Requests:
        if (impersonate) {
          handleAdminNotificationClick(id, data, pathname);
        } else {
          history.push({
            pathname: `/${pathname}`,
            state: { id },
          });
        }
        return;
      case RoutesNames.Payment:
        history.push(`${RoutesNames.Payment}`);
        return;
      default:
        history.push({
          pathname: `/${pathname}`,
          state: { id },
        });
    }
  };

  const { refetch: updateSingleNotificationStatus } = useUpdateSingleNotificationStatus((data) => {
    handleNotificationClick(data);
  });

  useEffect(() => {
    if (notificationsData && notificationsData.notifications) {
      if (pagination.page === 0) {
        setContent(notificationsData.notifications.content);
      } else {
        setContent([...content, ...notificationsData.notifications.content]);
      }
    }
  }, [notificationsData]);

  useEffect(() => {
    if (isIntersecting && content.length < get(notificationsData, 'notifications.totalElements')) {
      pagination.setPage(pagination.page + 1);
      loadMore();
      isIntersecting = false;
    }
  }, [isIntersecting]);

  const handleStatusChange = (status) => {
    updateNotificationsStatus({ data: { status } });
  };

  async function handleSingleNotificationClick(listId) {
    updateSingleNotificationStatus({ id: listId });
    handleClose();
  }

  return (
    <>
      <div className={classes.notificationDropdown}>
        <div className={classes.headerWrapper}>
          <div className={classes.dropdownHeader}>
            <Typography component='p' variant='body1' className={classes.dropTextBold}>
              Notifications
            </Typography>
            {!!content.length && (
              <Typography component='p' className={classes.markAsRead} onClick={() => handleStatusChange('READ')}>
                Mark all as read
              </Typography>
            )}
          </div>
        </div>
        {content.length ? (
          <ul>
            {content.map((item) => (
              <li
                className={`${classes.notificationItem} ${item.status !== 'READ' ? classes.newNotification : ''}`}
                key={item.id}
                // ref={content.length === index + 1 ? elementRef : null}
                onClick={() => handleSingleNotificationClick(item.id)}
              >
                <AvatarGroup items={item.actors} avatarClassName={classes.avatar} />
                <div className={classes.notoficationContent}>
                  <div className={classes.actorsName}>
                    <Typography component='p' variant='body1' className={classes.dropTextBold}>
                      {item.actors.map((actor, i) => `${actor.name}${i < item.actors.length - 1 ? ', ' : ''}`)}
                    </Typography>
                    <Typography component='p' className={classes.notificationTime}>
                      {getFormattedTime(item.dateTime)}
                    </Typography>
                  </div>
                  <Typography component='p' variant='body2'>
                    {item.message}
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className={classes.emptyCase}>
            <Typography component='p' variant='body1' className={classes.dropTextBold}>
              Nothing to see here yet!
            </Typography>
          </div>
        )}
      </div>

      {content.length < get(notificationsData, 'notifications.totalElements') ? (
        <CircularProgress
          style={{
            margin: '10px auto',
            width: '20px',
            height: '20px',
            display: 'flex',
          }}
          ref={elementRef}
        />
      ) : null}
    </>
  );
}
