import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import clsx from 'clsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import AppBar from '@material-ui/core/AppBar';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, MenuList } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useAppDispatch, useAppState } from 'contexts/PropertyEditContext';
import { impersonate as impersonateUser, signOut, unImpersonate } from 'api/auth';
import { useGetUserByType } from 'api/apiHooks/userHooks';
import { useGetNotifications, useUpdateNotificationsStatus } from 'api/apiHooks/notificationHooks';

import Button from 'components/BaseComponents/Button';
import Dialog from 'components/BaseComponents/Dialog';

import useApi from 'components/Hooks/useAPI';
import { userRequestBaseUrl } from 'utils/enums';

import ExitIcon from 'assets/images/exit.svg';

import Notifications from '../Notifications';
import { useStyles } from './appbar.style';

// TODO Refactor
export default forwardRef(({ handleMenuClick }, ref) => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:768px)');
  const history = useHistory();
  const { profile, impersonate, admin, lastPosition } = useAppState();
  const dispatch = useAppDispatch();
  const [notificationsData, setNotificationsData] = useState();
  const { pagination } = useApi();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [more, setMore] = React.useState(true);
  const [runTimeout, setRunTimeout] = React.useState(true);
  const [anchorelnotification, setAnchorelnotification] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorelnotification);
  const id = open ? 'simple-popper' : undefined;
  const idNotification = openNotification ? 'simple-popper' : undefined;
  const [userToImpersonate, setUserToImpersonate] = useState(null);
  const [pathToRedirect, setPathToRedirect] = useState(null);

  const { pathname } = useLocation();

  const { refetch: getNotifications } = useGetNotifications((data) => {
    pagination.setMaxPage(data.notifications.totalPages - 1);
    pagination.setMaxItemCount(data.totalElements);
    setNotificationsData(data);
    setMore(false);
  });

  const { refetch: updateNotificationStatus } = useUpdateNotificationsStatus(() => {
    setMore(true);
    getNotifications({
      params: { size: pagination.itemAmount, page: pagination.page },
    });
  });

  const fetchNotifications = () => {
    if (more) {
      getNotifications({
        params: { size: pagination.itemAmount, page: pagination.page },
      });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [pagination.page, more]);

  useEffect(() => {
    if (runTimeout) {
      const interval = setInterval(() => {
        fetchNotifications();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [runTimeout]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    pagination.setPage(0);
    setAnchorEl(null);
    setMore(true);
    setAnchorelnotification(null);
    setRunTimeout(true);
  };

  function loadMore() {
    setMore(true);
    pagination.setPage(pagination.page + 1);
  }

  const handleStatusChange = (status, event) => {
    setRunTimeout(false);
    if (status === 'NOTIFIED') {
      setAnchorelnotification(event.currentTarget);
    }
    updateNotificationStatus({ data: { status } });
  };

  async function logOut() {
    await signOut();
    history.push('/');
    dispatch({
      type: 'SIGN_OUT',
    });
  }

  const handleRedirection = (path) => {
    history.push({
      pathname: `/${path.pathname}`,
      state: { id: path.id },
    });
    setPathToRedirect(null);
  };

  async function handleUnimpersonate() {
    await unImpersonate();
    dispatch({
      type: 'SET_IMPERSONATE',
      payload: null,
    });
    dispatch({
      type: 'SET_PROFILE',
      payload: admin,
    });

    history.replace(lastPosition || '/');
  }

  const { refetch: getUserByType } = useGetUserByType((data) => {
    dispatch({
      type: 'SET_PROFILE',
      payload: data,
    });
    dispatch({
      type: 'SET_IMPERSONATE',
      payload: data,
    });

    if (pathToRedirect) {
      handleRedirection(pathToRedirect);
    }

    setUserToImpersonate(null);
  });

  const userImpersonate = () => {
    if (profile?.type === 'admin') {
      dispatch({
        type: 'SET_LASTPOSITION',
        payload: pathname,
      });
    }

    impersonateUser(userToImpersonate);
    getUserByType({ baseUrl: userRequestBaseUrl[userToImpersonate.type], userId: userToImpersonate.id });
  };

  useImperativeHandle(ref, () => ({
    logout: logOut,
  }));

  return (
    <AppBar className={classes.stickyMainHeader}>
      <Dialog
        text={`Are you sure you want to act as ${userToImpersonate?.fullName || ''}?`}
        confirmText='Yes'
        open={!!userToImpersonate}
        handleClose={() => setUserToImpersonate(null)}
        handleConfirm={userImpersonate}
      />
      <Toolbar className={classes.topBar}>
        {matches && pathname !== '/intro' && (
          <IconButton onClick={handleMenuClick} className={clsx(classes.customHover, classes.menuIcon)}>
            <MenuIcon />
          </IconButton>
        )}
        <div className={classes.buttonsWrapper}>
          {impersonate && (
            <Button onClick={handleUnimpersonate} version='bgWhite' className={classes.unImpersonate}>
              <img className={classes.exitIcon} src={ExitIcon} alt='' />
              Exit {impersonate.fullName} view
            </Button>
          )}
          <div className={classes.iconsContainer}>
            <IconButton
              className={classes.customHover}
              onClick={(event) => handleStatusChange('NOTIFIED', event)}
              aria-describedby={id}
            >
              <Badge
                overlap='rectangular'
                badgeContent={notificationsData && notificationsData.newCount}
                color='secondary'
                className={classes.notificationBadge}
              >
                <NotificationsIcon className={clsx(classes.headerIcons, { [classes.notificationIcon]: impersonate })} />
              </Badge>
            </IconButton>
            <Popover
              disableScrollLock
              className={classes.notificationPopover}
              id={idNotification}
              open={!!openNotification}
              anchorEl={anchorelnotification}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Notifications
                userImpersonate={setUserToImpersonate}
                getRedirectionPath={setPathToRedirect}
                handleRedirection={handleRedirection}
                notificationsData={notificationsData}
                fetchNotifications={fetchNotifications}
                handleClose={handleClose}
                loadMore={loadMore}
                setMore={setMore}
              />
            </Popover>
            <IconButton className={classes.customHover}>
              <Link to={pathname === '/intro' ? '/intro' : '/profile'}>
                {profile && profile.photoUrl ? (
                  <img src={profile.photoUrl} alt='profile pic' className={classes.photo} />
                ) : (
                  <AccountCircle className={clsx(classes.headerIcons)} />
                )}
              </Link>
            </IconButton>
            {!matches && (
              <IconButton
                onClick={handleClick}
                aria-describedby={id}
                className={classes.customHover}
                data-testid='userdropdown'
              >
                <ExpandMoreIcon className={classes.headerIcons} />
              </IconButton>
            )}

            <Popover
              disableScrollLock
              style={{ borderRadius: '24px' }}
              className={classes.popover}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuList className={classes.navDropdown} onClick={handleClose}>
                <MenuItem disableGutters className={classes.dropdownItem}>
                  <Link to={pathname === '/intro' ? '/intro' : '/profile'}>Profile settings</Link>
                </MenuItem>
                {profile?.type === 'tenant' && (
                  <MenuItem disableGutters className={classes.dropdownItem}>
                    <Link to='/payment-settings'>Payment settings</Link>
                  </MenuItem>
                )}
                <MenuItem disableGutters className={classes.dropdownItem} onClick={() => logOut()} data-testid='logOut'>
                  Log out
                </MenuItem>
              </MenuList>
            </Popover>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
});
