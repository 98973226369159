import styled from 'styled-components';
import theme from '../../theme';

export const MissingText = styled.p`
  position: absolute;
  left: 0;
  bottom: ${(props) => props?.bottom || '-12px'};
  color: #f6911a;
  padding-bottom: 15px;
  font-family: ${theme.typography.fontFamily};
  white-space: nowrap;
`;
