import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: '500',
  },
  subtitle: {
    marginBottom: theme.spacing(4),
  },
  textWrapper: {
    paddingBottom: '12px',
  },
}));

export const LogoWrapper = styled.div`
  position: absolute;
  top: 24px;
  @media (max-width: 960px) {
    width: 100%;
    height: 73px;
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      height: 42px;
    }
  }
`;
