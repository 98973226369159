import { singleRequestHandler } from '../requestHandlers';
import { paymentEndpoints } from './endpointEnums';

const { PAYMENT_METHOD, SUBSCRIPTION, TENANT_PAYS } = paymentEndpoints;

export const addPaymentMethodService = (dependency) => {
  const { data, userType } = dependency;
  const request = {
    url: `/${PAYMENT_METHOD}/${userType}`,
    method: 'PUT',
    data,
  };
  return singleRequestHandler(request);
};

export const getPaymentMethodService = (dependency) => {
  const { params, userType } = dependency;
  const request = {
    url: `/${PAYMENT_METHOD}/${userType}`,
    method: 'GET',
    params,
  };

  return singleRequestHandler(request);
};

export const updatePaymentMethodService = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${PAYMENT_METHOD}/${SUBSCRIPTION}`,
    method: 'PUT',
    data,
  };

  return singleRequestHandler(request);
};

export const updatePaymentMethodByTenantService = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${PAYMENT_METHOD}/${TENANT_PAYS}`,
    method: 'PUT',
    data,
  };

  return singleRequestHandler(request);
};
