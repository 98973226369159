import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    marginLeft: '243px',
    '@media (max-width:768px)': {
      marginLeft: 0,
    },
    '& .MuiDialog-paper': {
      width: '512px',
      padding: '40px 32px',
      borderRadius: theme.spacing(3),
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 11px 7px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    },
  },
  actions: {
    '&.MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  },
  successImage: {
    marginBottom: '24px',
  },
}));

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
