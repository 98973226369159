import React from 'react';

import { PortalLogo } from 'components/BaseComponents/Icons';
import { LogoWrapper } from './signUpIntroduction.style';

export default () => {
  return (
    <LogoWrapper>
      <PortalLogo />
    </LogoWrapper>
  );
};
