import { singleRequestHandler } from '../requestHandlers';
import { authEndpoints } from './endpointEnums';

const { AUTH, TEMPORARY_PASSWORD_CHANGE, FORGOT_PASSWORD, SIGN_UP } = authEndpoints;

export const updateTemporaryPassword = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${AUTH}/${TEMPORARY_PASSWORD_CHANGE}`,
    method: 'PATCH',
    data,
    unAuth: true,
  };

  return singleRequestHandler(request);
};

export const resendResetPasswordLink = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${AUTH}/${FORGOT_PASSWORD}`,
    method: 'POST',
    data,
    unAuth: true,
  };

  return singleRequestHandler(request);
};

export const signUpService = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${AUTH}/${SIGN_UP}`,
    method: 'POST',
    data,
    unAuth: true,
  };

  return singleRequestHandler(request);
};
