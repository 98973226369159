import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: ({ customMargin }) => customMargin || '0px 0px 8px',
  },
  button: {
    '&.flag-dropdown': {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ invalid }) => (!invalid ? '#FCC425' : theme.errorColor),
      '&.invalid-number': {
        borderColor: ({ invalid }) => (!invalid ? '#FCC425' : theme.errorColor),
      },
    },
  },
  input: {
    height: 40,
    width: '100%',
    borderRadius: 8,
    paddingLeft: 48,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: ({ invalid }) => (!invalid ? '#FCC425' : theme.errorColor),
  },

  errorMessage: {
    color: theme.errorColor,
    position: 'absolute',
    top: 39,
    whiteSpace: ({ noWrap }) => (noWrap ? 'nowrap' : ''),
    fontSize: 14,
  },
}));
