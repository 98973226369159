import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import PetsIcon from '@material-ui/icons/Pets';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import {
  HomeIcon,
  DocumentIcon,
  DashboardIcon,
  ViolationsIcon,
  GraduationHatIcon,
  SupportStaffIcon,
  SettingsSidebarIcon,
} from 'components/BaseComponents/Icons';
import { allowedUserTypes } from 'routes/RoutesData';

const defaultTabs = [
  {
    label: 'Dashboard',
    route: 'dashboard',
    icon: <DashboardIcon />,
    dataCy: 'dashboard',
  },
  {
    label: 'Properties',
    route: 'properties',
    icon: <HomeIcon />,
    dataCy: 'properties',
  },
  {
    label: 'Residents',
    route: 'contracts',
    icon: <SupervisorAccountIcon />,
    dataCy: 'contracts',
  },
  {
    label: 'Managers',
    route: 'maintainers',
    icon: <SupportStaffIcon />,
    dataCy: 'managers',
    subRoutes: [
      { label: 'Support staff', route: 'maintainers', dataCy: 'maintainers' },
      { label: 'Property managers', route: 'property-managers', dataCy: 'propertyManagers' },
    ],
  },
  {
    label: 'Policies',
    route: 'policies',
    icon: <DocumentIcon />,
    dataCy: 'policies',
    subRoutes: [
      { label: 'Policy list', route: 'policies', dataCy: 'policies' },
      { label: 'Default state policies', route: 'default-policies', dataCy: 'default-policies' },
    ],
  },
  {
    label: 'Animal complaints',
    route: 'violations',
    icon: <PetsIcon />,
    dataCy: 'violations',
  },
  {
    label: 'Animals',
    route: 'requests',
    icon: <ViolationsIcon />,
  },
];

export const tabList = ({ impersonate, profile }) => {
  const { tenant, landlord, admin, propertyManager } = allowedUserTypes;
  return {
    [landlord]: [
      ...defaultTabs,
      ...(profile.hasAccessToCourses
        ? [
            {
              label: 'Curriculum',
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      {
        label: 'Settings',
        route: 'settings',
        icon: <SettingsSidebarIcon />,
        subRoutes: [
          { label: 'Payments', route: 'settings' },
          { label: 'Integrations', route: 'integration-settings' },
        ],
      },
      ...(impersonate
        ? []
        : [
            {
              label: 'Support',
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
    ],
    [propertyManager]: [
      ...defaultTabs,
      ...(profile.hasAccessToCourses
        ? [
            {
              label: 'Curriculum',
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      ...(impersonate
        ? []
        : [
            {
              label: 'Support',
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
    ],

    [tenant]: [
      {
        label: 'Animal policy',
        route: 'tenant-contracts',
        icon: <SupervisorAccountIcon />,
        dataCy: 'tenant-contracts',
      },
      {
        label: 'Animal complaints',
        route: 'violations',
        icon: <PetsIcon />,
        dataCy: 'violations',
      },
      {
        label: 'Animals',
        route: 'requests',
        // then why this called ViolationsIcon
        icon: <ViolationsIcon />,
      },
      ...(profile.hasCurriculum
        ? [
            {
              label: 'Curriculum',
              route: 'curriculum',
              icon: <GraduationHatIcon color='#FCC425' />,
            },
          ]
        : []),
      ...(impersonate
        ? []
        : [
            {
              label: 'Support',
              route: 'support',
              icon: <HelpIcon />,
              dataCy: 'support',
            },
          ]),
    ],

    [admin]: [
      {
        label: 'Animals',
        route: 'requests',
        icon: <ViolationsIcon />,
      },
      {
        label: 'Landlords',
        route: 'landlords',
        icon: <BusinessIcon />,
      },
      {
        label: 'Tenants',
        route: 'tenants',
        icon: <SupervisorAccountIcon />,
      },
      {
        label: 'Curriculum',
        route: 'curriculum',
        icon: <GraduationHatIcon color='#FCC425' />,
      },
      {
        label: 'State policy',
        route: 'state-policy',
        icon: <DescriptionIcon />,
      },
      {
        label: 'Document upload',
        route: 'documents',
        icon: <ViolationsIcon />,
      },
      {
        label: 'Suggested texts',
        route: 'suggested-texts',
        icon: <FontDownloadIcon />,
      },
    ],
  };
};
