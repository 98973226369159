import { singleRequestHandler } from '../requestHandlers';
import { notificationEndpoints } from './endpointEnums';

const { NOTIFICATION } = notificationEndpoints;

export const getNotificationsService = (dependency) => {
  const { params } = dependency;
  const request = {
    url: `/${NOTIFICATION}`,
    method: 'GET',
    params,
  };

  return singleRequestHandler(request);
};

export const updateNotificationsStatusService = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${NOTIFICATION}`,
    method: 'PATCH',
    data,
  };

  return singleRequestHandler(request);
};

export const updateSingleNotificationStatusService = (dependency) => {
  const { id, data, params } = dependency;
  const request = {
    url: `/${NOTIFICATION}/${id}`,
    method: 'PATCH',
    data,
    params,
  };

  return singleRequestHandler(request);
};
