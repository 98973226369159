import { useApiResult } from './abstractApiHooks';
import {
  getNotificationsService,
  updateNotificationsStatusService,
  updateSingleNotificationStatusService,
} from '../services/notificationServices';

export const useGetNotifications = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(getNotificationsService, onSuccess, onError);
};

export const useUpdateNotificationsStatus = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateNotificationsStatusService, onSuccess, onError);
};

export const useUpdateSingleNotificationStatus = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateSingleNotificationStatusService, onSuccess, onError);
};
