import { updateTemporaryPassword, resendResetPasswordLink, signUpService } from 'api/services/authServices';
import { useApiResult } from './abstractApiHooks';

export const useUpdatePassword = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateTemporaryPassword, onSuccess, onError);
};

export const useResendResetPasswordLink = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(resendResetPasswordLink, onSuccess, onError);
};

export const useSignUp = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(signUpService, onSuccess, onError);
};
