// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { RoutesNames } from '../routes/RoutesNames';

export const petTypeEnams = {
  'Regular Pet': 'PET_FORM',
  ESA: 'ESA_FORM',
  'Service Animal': 'SERVICE_FORM',
  'Animal Visitation': 'ANIMAL_VISITATION',
};

export const petStatisticsTypes = {
  ESA_FORM: 'esaStarted',
  SERVICE_FORM: 'saStarted',
};

export const documentItems = {
  PET_FORM: {
    title: 'Pet Application',
    type: 'Regular Pet',
    name: {
      label: 'Pet name',
      placeholder: 'e.g. Sparky',
    },
    form: [
      { title: 'Pet Application', docKey: 'PET_FORM' },
      { title: 'Animal Info and Behavior', docKey: 'ANIMAL_BEHAVIOR_FORM' },
      { title: 'Veterinary records', docKey: 'VET_RECORDS', optional: true },
    ],
  },
  ESA_FORM: {
    title: 'Emotional Support Animal Application',
    type: 'ESA',
    name: {
      label: 'Animal name',
      placeholder: 'e.g. Max',
    },
    form: [
      { title: 'Emotional Support Animal Application', docKey: 'ESA_FORM' },
      { title: 'Animal Info and Behavior', docKey: 'ANIMAL_BEHAVIOR_FORM' },
      { title: 'Veterinary records', docKey: 'VET_RECORDS', optional: true },
      {
        title: 'Health Professional Form/ESA Letter',
        docKey: 'MENTAL_HEALTH_PROFESSIONAL',
        optional: true,
      },
    ],
  },
  SERVICE_FORM: {
    title: 'Service Animal Application',
    type: 'Service Animal',
    name: {
      label: 'Animal name',
      placeholder: 'e.g. Max',
    },
    form: [
      { title: 'Service Animal Application', docKey: 'SERVICE_FORM' },
      { title: 'Animal Info and Behavior', docKey: 'ANIMAL_BEHAVIOR_FORM' },
      {
        title: 'Documentation of Service animal training',
        docKey: 'SERVICES_TRAINED',
      },
      { title: 'Veterinary records', docKey: 'VET_RECORDS', optional: true },
    ],
  },
  ANIMAL_VISITATION: {
    title: 'Animal Visitation Application',
    type: 'Animal Visitation',
    name: {
      label: 'Animal name',
      placeholder: 'e.g. Max',
    },
    form: [{ title: 'Animal Visitation Application', docKey: 'ANIMAL_VISITATION' }],
  },
};

export const documentTypesMap = {
  regularPet: { title: 'Pet Application', docKey: 'PET_FORM' },
  esa: { title: 'Emotional Support Animal Application', docKey: 'ESA_FORM' },
  serviceAnimal: {
    title: 'Service Animal Application',
    docKey: 'SERVICE_FORM',
  },
  animalVisitation: {
    title: 'Animal Visitation Application',
    docKey: 'ANIMAL_VISITATION',
  },
};

export const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const cebabize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => {
      return word.toUpperCase();
    })
    .replace(/\s+/g, '-');
};

export const snakize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => {
      return word.toUpperCase();
    })
    .replace(/\s+/g, '_');
};

export const suggestionsType = {
  WASTE_MANAGEMENT_INSTRUCTIONS: 'wasteManagementInstructions',
  PET_RESTRICTIONS: 'petRestrictions',
  PET_RULES: 'petRules',
};

export const suggestionsTypeLabel = {
  [suggestionsType.WASTE_MANAGEMENT_INSTRUCTIONS]: 'Waste management instruction',
  [suggestionsType.PET_RESTRICTIONS]: 'Pet restriction',
  [suggestionsType.PET_RULES]: 'Pet rule',
};

export const defaultPagePath = {
  tenant: {
    true: RoutesNames.Intro,
    false: RoutesNames.TenantContracts,
  },
  landlord: {
    true: RoutesNames.Intro,
    false: RoutesNames.Dashboard,
  },
  propertyManager: {
    true: RoutesNames.Dashboard,
    false: RoutesNames.Dashboard,
  },
  admin: {
    true: RoutesNames.Requests,
    false: RoutesNames.Requests,
  },
};

export const userRoles = {
  ADMIN: 'admin',
  LANDLORD: 'landlord',
  TENANT: 'tenant',
  PROPERTY_MANAGER: 'propertyManager',
};

export const routeAfterIntro = {
  [userRoles.LANDLORD]: RoutesNames.Onboarding,
  [userRoles.TENANT]: RoutesNames.TenantContracts,
  [userRoles.PROPERTY_MANAGER]: RoutesNames.Dashboard,
};

export const userRequestBaseUrl = {
  admin: 'admin',
  landlord: 'landlord',
  tenant: 'tenant',
  propertyManager: 'property_managers',
};

export const notLoggedInUserStatuses = ['Invited', 'notInvited'];

export const blockInvalidChar = (event) => ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
export const blockNotNumberChars = (event) => ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault();
