import React, { useEffect, useReducer } from 'react';
import GoogleAnalytics from 'react-ga';

export const AppStateContext = React.createContext();
export const AppDispatchContext = React.createContext();

export const PropertyEditProvider = (props) => {
  const initialState = {
    properties: [],
    policies: [],
    contracts: [],
    violations: [],
    resMan: {
      resManId: null,
      syncPeriodicity: null,
    },
    loading: false,
    selectedProperty: {},
    cardProvided: true,
    card: {},
    selectedContract: null, // TODO remove selectedContract from the context
    profile: null,
    admin: null,
    cardFetched: false,
    alertObject: {},
    cardHolder: '',
    cardholderMissing: false,
    impersonate: null,
    lastPosition: '/',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'LOADING': {
        return { ...state, loading: true };
      }
      case 'NOT_LOADING': {
        return { ...state, loading: false };
      }
      case 'CLOSE_ALERT': {
        return { ...state, alertObject: {} };
      }
      case 'OPEN_ALERT': {
        return {
          ...state,
          alertObject: {
            message: action.payload.message,
            severity: action.payload.severity,
          },
        };
      }
      case 'SET_PROFILE': {
        return {
          ...state,
          profile: action.payload,
        };
      }
      case 'SET_IMPERSONATE': {
        return {
          ...state,
          impersonate: action.payload,
        };
      }
      case 'SET_ADMIN': {
        return {
          ...state,
          admin: action.payload,
        };
      }
      case 'SET_LASTPOSITION': {
        return {
          ...state,
          lastPosition: action.payload,
        };
      }
      case 'ADD_PROPERTIES_AND_POLICIES': {
        return {
          ...state,
          properties: action.payload.properties,
          policies: action.payload.policies,
        };
      }
      case 'ADD_PROPERTIES': {
        return {
          ...state,
          properties: action.payload.properties,
        };
      }
      case 'ADD_POLICIES': {
        return {
          ...state,
          policies: action.payload.policies,
          loading: false,
        };
      }
      case 'SELECT_PROPERTY': {
        return {
          ...state,
          selectedProperty: action.payload,
        };
      }
      case 'SELECT_POLICY': {
        return {
          ...state,
          selectedPolicy: action.payload,
        };
      }
      case 'SELECT_VIOLATION': {
        return {
          ...state,
          selectedViolation: action.payload,
        };
      }
      case 'ADD_PROPERTY': {
        return {
          ...state,
          properties: [...action.payload, ...state.properties],
        };
      }
      case 'SET_CONTRACTS': {
        return {
          ...state,
          contracts: [...action.payload],
        };
      }
      case 'ADD_CONTRACT': {
        return {
          ...state,
          contracts: [...action.payload, ...state.contracts],
        };
      }
      case 'SELECT_CONTRACT': {
        return {
          ...state,
          selectedContract: action.payload,
        };
      }
      case 'ADD_VIOLATION': {
        return {
          ...state,
          violations: [...action.payload, ...state.violations],
        };
      }

      case 'EDIT_CONTRACT': {
        const updatedContracts = [...state.contracts];
        const index = updatedContracts.findIndex((p) => p.id === action.payload.id);
        updatedContracts[index] = action.payload;
        return { ...state, contracts: updatedContracts };
      }
      case 'DELETE_CONTRACT': {
        const copyOfContracts = [...state.contracts];
        const index = copyOfContracts.findIndex((p) => p.id === action.payload.id);
        const updatedContracts = [...copyOfContracts.slice(0, index), ...copyOfContracts.slice(index + 1)];
        return { ...state, properties: updatedContracts };
      }
      case 'EDIT_PROPERTY': {
        const updatedProperties = [...state.properties];
        const index = updatedProperties.findIndex((p) => p.id === action.payload.id);
        updatedProperties[index] = action.payload;
        return { ...state, loading: false, properties: updatedProperties };
      }
      case 'DELETE_PROPERTY': {
        const copyOfProperties = [...state.properties];
        const index = copyOfProperties.findIndex((p) => p.id === action.payload.id);
        const updatedProperties = [...copyOfProperties.slice(0, index), ...copyOfProperties.slice(index + 1)];
        return { ...state, loading: false, properties: updatedProperties };
      }
      case 'ADD_POLICY': {
        return {
          ...state,
          loading: false,
          policies: [...action.payload, ...state.policies],
        };
      }
      case 'EDIT_POLICY': {
        const updatedPolicies = [...state.policies];
        const index = updatedPolicies.findIndex((p) => p.id === action.payload.id);
        updatedPolicies[index] = action.payload;
        return { ...state, loading: false, policies: updatedPolicies };
      }
      case 'DELETE_POLICY': {
        const copyOfPolicies = [...state.policies];
        const index = copyOfPolicies.findIndex((p) => p.id === action.payload.id);
        const updatedPolicies = [...copyOfPolicies.slice(0, index), ...copyOfPolicies.slice(index + 1)];
        return { ...state, loading: false, policies: updatedPolicies };
      }
      case 'SET_CARD': {
        return {
          ...state,
          cardProvided: !!action.payload,
          card: action.payload || null,
          cardFetched: true,
        };
      }
      case 'SIGN_OUT': {
        return {
          properties: [],
          policies: [],
          contracts: [],
          violations: [],
          loading: false,
          selectedProperty: {},
          cardProvided: true,
          card: {},
          selectedContract: null,
          profile: null,
          admin: null,
          impersonate: null,
          cardFetched: false,
          alertObject: {},
          cardHolder: '',
          cardholderMissing: false,
          lastPosition: null,
        };
      }
      case 'CHANGE_PROFILE_PICTURE': {
        const updatedProfile = { ...state.profile };
        updatedProfile.photoUrl = action.payload;
        return {
          ...state,
          profile: updatedProfile,
        };
      }
      case 'CHANGE_FULL_NAME': {
        const updatedProfile = { ...state.profile };
        updatedProfile.fullName = action.payload;
        return {
          ...state,
          profile: updatedProfile,
        };
      }
      case 'SET_CARDHOLDER': {
        return {
          ...state,
          cardholder: action.payload,
        };
      }
      case 'CARDHOLDER_MISSING': {
        return {
          ...state,
          cardholderMissing: action.payload,
        };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!state.profile?.id) {
      return;
    }
    const { profile, impersonate } = state;
    GoogleAnalytics.event({
      category: `${profile.id}-${state.profile.username?.split('@')[0] || state.profile.fullName}`,
      action: !profile.impersonated ? 'Log-in' : 'Impersonate',
      label: !profile.impersonated ? profile.type : `${profile.type}-impersonate`,
    });
  }, [state.profile?.id]);

  return (
    <AppDispatchContext.Provider value={dispatch}>
      <AppStateContext.Provider value={state}>{props.children}</AppStateContext.Provider>
    </AppDispatchContext.Provider>
  );
};

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider');
  }
  return context;
}
function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a CountProvider');
  }
  return context;
}

export { useAppState, useAppDispatch };
