import React from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MissingMessage from '../missingMessage';

import { useStyles } from './textField.style';

export default ({
  nomargin,
  noWrap,
  invalid,
  rows,
  value,
  hideZero,
  testid,
  classNames,
  missing,
  className,
  wrapperClassName,
  errors,
  ...props
}) => {
  const classes = useStyles({ nomargin, invalid, rows, noWrap });
  return (
    <div
      style={{ position: 'relative' }}
      className={clsx([classNames?.wrapper, { [wrapperClassName]: wrapperClassName }])}
    >
      <TextField
        {...props}
        rows={rows}
        data-testid={testid}
        value={hideZero ? value || '' : value}
        className={clsx([classes.root, { [classes.missing]: missing }], { [className]: className })}
      />
      {errors && (
        <Typography component='p' variant='body1' className={classes.errorMessage}>
          {errors}
        </Typography>
      )}
      {missing && <MissingMessage />}
    </div>
  );
};
