import React from 'react';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import FormLabel from '@material-ui/core/FormLabel';

import { PhoneInput as PhoneInputFeild } from 'components/BaseComponents/PhoneInput';

import { SFeild } from './phoneInput.style';

export function PhoneInput({
  label = 'Phone number',
  errorMessage,
  onChangeHandler,
  initialValue,
  onKeyDown,
  onBlurHandler = () => {},
  onTabKey,
  ...rest
}) {
  return (
    <>
      <Grid item xs={12}>
        <FormLabel component='legend'>{label}</FormLabel>
      </Grid>
      <Grid item xs={12}>
        <SFeild
          {...rest}
          id='phone'
          name='phone'
          type='number'
          autoComplete='phone'
          placeholder='Phone number'
          invalid={!!errorMessage}
          InputProps={{
            startAdornment: <AddIcon />,
          }}
          as={({ invalid, ...rest }) => (
            <PhoneInputFeild
              {...rest}
              name='phone'
              error={errorMessage}
              invalid={!!errorMessage}
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              defaultValues={initialValue}
              onKeyDown={(e) => {
                if (!onTabKey || (e.key !== 'Tab' && e.key !== 'Enter')) {
                  return;
                }
                onTabKey();
                e.preventDefault();
              }}
            />
          )}
        />
      </Grid>
    </>
  );
}
