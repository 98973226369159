import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  notificationDropdown: {
    background: theme.palette.secondary.main,
    zIndex: 111,
    '& ul': {
      listStyleType: 'none',
      '& li': {
        padding: '12px 24px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#FCFCFC',
        },

        '@media(max-width:960px)': {
          padding: '12px 21px',
        },
      },
    },
  },
  headerWrapper: {
    padding: '24px 24px 0',
    position: 'sticky',
    background: '#ffffff',
    top: 0,
    left: 0,
    zIndex: 7,
    marginBottom: 20,

    '@media(max-width:960px)': {
      marginBottom: 19,
    },
  },
  dropdownHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 19,
    borderBottom: `1px solid ${theme.palette.primary.main}`,

    '@media(max-width:960px)': {
      paddingBottom: 20,
    },
  },
  dropTextBold: {
    fontWeight: 700,
  },
  markAsRead: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 700,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  notificationItem: {
    display: 'flex',
    alignItems: 'center',
  },
  notoficationContent: {
    flexGrow: 1,
    paddingLeft: '16px',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  newNotification: {
    backgroundColor: theme.backgroundColor,
  },
  notificationTime: {
    marginLeft: 'auto',
    fontSize: '12px',
    color: '#C4C4C4',
  },
  emptyCase: {
    textAlign: 'center',
    padding: '30px 0 55px',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  actorsName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
}));
