const getLandlordSignUpRequestData = ({ email, password, name, businessName, phone }) => {
  const customAttributes = {
    name: name.trim(),
    email: email.toLowerCase(),
    'custom:userType': 'landlord',
    'custom:showIntro': 'true',
    'custom:showOnboard': 'true',
    'custom:hasProfile': 'false',
  };

  if (phone) {
    customAttributes.phone_number = phone;
  }

  return {
    userType: 'landlord',
    username: email?.toLowerCase() || phone,
    email: email || null,
    phone: phone || null,
    password,
    fullName: name.trim(),
    businessName: businessName.trim(),
    customAttributes,
  };
};

const getTenantSignUpRequestData = ({ email, password, name, phone, haveAnimal, roommateHasAnimal }) => {
  const customAttributes = {
    name: name.trim(),
    'custom:haveAnimal': haveAnimal,
    'custom:roommateHasAnimal': roommateHasAnimal,
    'custom:userType': 'tenant',
    'custom:showIntro': 'true',
    'custom:showOnboard': 'true',
    'custom:hasProfile': 'false',
  };

  if (email) {
    customAttributes.email = email.toLowerCase();
  } else {
    customAttributes.phone_number = phone;
  }

  return {
    userType: 'tenant',
    username: email?.toLowerCase() || phone,
    email: email || null,
    phone: phone || null,
    password,
    fullName: name.trim(),
    customAttributes,
  };
};

export const getUserSignUpRequestData = (userToCreate) => {
  switch (userToCreate.accountType) {
    case 'landlord': {
      return getLandlordSignUpRequestData(userToCreate);
    }
    case 'tenant': {
      return getTenantSignUpRequestData(userToCreate);
    }
    default:
  }
};

export const errorMessageEnums = {
  phoneExists: 'An account with the given phone number already exists.',
  emailExists: 'An account with the given email already exists.',
};
