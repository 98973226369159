export const introContent = {
  landlord: {
    title: 'Welcome to OurPetPolicy',
    subtitle: 'Helping protect properties one contract at a time',
    tutorialTitle:
      'Here is a quick video tutorial that shows you how to easily add a property and send a contract in minutes!',
    videosrc: 'https://opp-static-content.s3.us-west-2.amazonaws.com/getting-started-video/Landlord+-+intro.mp4',
    benefits: [
      'Easy management for both landlords and tenants',
      'Reduce the number of fraudulent ESA letters',
      'Increase revenue with lawyer-vetted policies',
      'Reduce liability and potential legal disputes',
      'Minimize pet damage and the associated costs',
    ],
  },

  tenant: {
    title: 'Welcome to OurPetPolicy',
    subtitle: 'Helping protect both you and your landlord',
    tutorialTitle: 'Here is a quick video tutorial that shows you how to easily access your contract and applications!',
    videosrc: 'https://opp-static-content.s3.us-west-2.amazonaws.com/getting-started-video/Tenant+-+intro.mp4',
    benefits: [
      'Easy-to-use tenant portal',
      'Electronic signatures service',
      'Online application process for all animal requests',
      'ESA onboarding and verification system',
      'Online filing for veterinary records',
    ],
  },
};
