import { singleRequestHandler } from '../requestHandlers';
import { userEndpoints } from './endpointEnums';

const { USER, STATUS, PHOTO_URL, FULL_NAME } = userEndpoints;

export const getUserService = (dependency) => {
  const { id } = dependency;
  const request = {
    url: `/${USER}/${id}`,
    method: 'GET',
  };

  return singleRequestHandler(request);
};

export const getUserByTypeService = (dependency) => {
  const { baseUrl, userId } = dependency;
  const request = {
    url: `/${baseUrl}/${userId}`,
    method: 'GET',
    params: { userId },
  };

  return singleRequestHandler(request);
};

export const updateUserStatusService = (dependency) => {
  const { data } = dependency;
  const request = {
    url: `/${USER}/${STATUS}`,
    method: 'PATCH',
    data,
  };

  return singleRequestHandler(request);
};

export const updateProfileImageService = (dependency) => {
  const { userId, data } = dependency;
  const request = {
    url: `/${USER}/${userId}/${PHOTO_URL}`,
    method: 'PATCH',
    data,
  };

  return singleRequestHandler(request);
};

export const updateFullNameService = (dependency) => {
  const { userId, data } = dependency;
  const request = {
    url: `/${USER}/${userId}/${FULL_NAME}`,
    method: 'PATCH',
    data,
  };

  return singleRequestHandler(request);
};

export const getUserByUsernameService = (dependency) => {
  const { username } = dependency;
  const request = {
    url: `/${USER}`,
    method: 'GET',
    params: { username },
  };

  return singleRequestHandler(request);
};
