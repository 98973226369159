import { object, string } from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const validationSchema = (registerUsing) => {
  const validationObject = {
    password: string().required('Password is required'),
  };

  if (registerUsing === 'phone') {
    const phoneRegExp = /^(\d{11}|\d{12})$/;
    validationObject.phone = string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .test('Phone number test', 'Phone number is not valid', (value) => isValidPhoneNumber(`+${value}`));
    delete validationObject.email;
  } else {
    validationObject.email = string().email('Invalid Email Address').required('Email is required');
    delete validationObject.phone;
  }

  return object(validationObject);
};
