import React from 'react';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import TextIcon from 'components/BaseComponents/TextIcon';

export default function IntroContent({ title, list, classNames }) {
  return (
    <>
      <Typography component='h4' variant='h4' className={classNames.title}>
        {title}
      </Typography>
      {list.map((text) => (
        <div className={classNames.textWrapper} key={text}>
          <TextIcon className={classNames.list} text={text} Icon={CheckIcon} reverse='true' />
        </div>
      ))}
    </>
  );
}
