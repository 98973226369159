import React from 'react';
import { Field } from 'formik';
import { styled } from '@material-ui/core/styles';

export const SFeild = styled(({ as, ...rest }) => (
  <Field fullWidth as={as} margin='normal' variant='outlined' {...rest} />
))({
  margin: '-8px 0 8px',
  '& + div': {
    top: 32,
  },
});
