import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  avatarGroup: {
    padding: '0 8px 0 16px',
    '& .MuiAvatarGroup-avatar': {
      border: '2px solid #ffffff',
      marginLeft: '-20px',
      zIndex: '1!important',
      '& .MuiAvatar-circle': {
        backgroundColor: `${theme.secondaryTextColor}`,
        color: `${theme.palette.secondary.main}`,
      },
    },
  },
}));

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `${theme.palette.secondary.main}`,
    color: `${theme.typography.color}`,
    fontSize: 14,
    borderRadius: 8,
    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.12)',
    padding: '8px 16px',
  },
}))(Tooltip);
