import React, { useEffect, useState } from 'react';
import SidebarLogo from 'assets/images/SidebarLogo.svg';
import { SWrapper } from './style';

const SuspenseLoading = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!show) return null;

  return (
    <SWrapper>
      <img src={SidebarLogo} alt='Logo' />
    </SWrapper>
  );
};

export default SuspenseLoading;
