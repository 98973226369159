import React from 'react';
import { SList, SListItem, StyledLink } from '../sidbar.style';

const SubMenu = ({ routes }) => {
  if (!routes || !routes.length) return null;
  return (
    <SList>
      {routes.map(({ label, route }) => (
        <SListItem key={route}>
          <StyledLink to={`/${route}`} activeClassName='any'>
            {label}
          </StyledLink>
        </SListItem>
      ))}
    </SList>
  );
};

export default SubMenu;
