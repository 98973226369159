import React, { memo } from 'react';
import { CircularProgress } from '@material-ui/core';

import { Wrapper } from './loader.style';

function Loader({ loading, size, className }) {
  if (!loading) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <CircularProgress size={size} />
    </Wrapper>
  );
}

export default memo(Loader);
