import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import Success from 'assets/images/Success.svg';
import Button from '../Button';

import { DialogActions, DialogContent, useStyles } from './dialog.style';

export default function CustomizedDialogs({
  text,
  loading,
  confirmText,
  cancelText = 'Cancel',
  open,
  handleClose,
  handleConfirm,
  onExited,
  hideActions,
  success,
  showOk,
  children,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        aria-labelledby='customized-dialog-title'
        open={open}
        className={classes.dialog}
        onClose={handleClose}
        TransitionProps={{ onExited }}
      >
        <DialogContent>
          {children ? (
            <>{children}</>
          ) : (
            <>
              {success && (
                <div className={classes.successImage}>
                  <img src={Success} alt='success' />
                </div>
              )}
              <Typography gutterBottom>{text}</Typography>
            </>
          )}
        </DialogContent>
        {!hideActions && (
          <DialogActions className={classes.actions}>
            <Button autoFocus onClick={handleConfirm} color='primary' loading={loading}>
              {confirmText}
            </Button>
            <Button version='secondary' border='secondary' autoFocus onClick={handleClose} color='primary'>
              {cancelText}
            </Button>
          </DialogActions>
        )}
        {showOk && (
          <DialogActions className={classes.actions}>
            <Button onClick={handleClose} color='primary' loading={loading}>
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
