import { useEffect, useRef } from 'react';

const useDidUpdate = (callback, conditions) => {
  const didMountRef = useRef(false);
  const isSafe = useRef(true);
  useEffect(() => {
    if (didMountRef.current && isSafe) {
      callback();
    } else didMountRef.current = true;
    return () => {
      isSafe.current = false;
    };
  }, conditions);
};

export default useDidUpdate;
