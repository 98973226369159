import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { isValidPhoneNumber } from 'react-phone-number-input';

// todo refactor this 'peace of art' react-phone-number-input validate method is bettor then react-phone-input-2
import 'react-phone-input-2/lib/style.css';
import ReactPhoneInput from 'react-phone-input-2';

import { useStyles } from './phoneInput.style';

export function PhoneInput({
  name,
  invalid,
  onValidation = () => {},
  onChange,
  defaultValues,
  error,
  onKeyDown,
  customMargin,
  ...rest
}) {
  const classes = useStyles({ invalid, customMargin });
  const [value, setValue] = useState();

  useEffect(() => {
    if (!defaultValues) {
      return;
    }
    setValue(defaultValues);
  }, [defaultValues]);

  return (
    <div style={{ position: 'relative' }}>
      <ReactPhoneInput
        {...rest}
        country='us'
        buttonClass={classes.button}
        containerClass={classes.container}
        inputProps={{ name, className: classes.input }}
        value={value}
        onChange={setValue}
        onBlur={() => {
          onChange(value);
        }}
        onKeyDown={onKeyDown}
        isValid={(inputNumber) => {
          if (!invalid && (inputNumber || '').length < 3) {
            onValidation(true);
            return true;
          }
          // logInRef.current.validPhoneNumber = isValid;
          return isValidPhoneNumber(`+${inputNumber}`);
        }}
      />
      {error && (
        <Typography component='span' variant='body1' className={classes.errorMessage}>
          {error}
        </Typography>
      )}
    </div>
  );
}
