import Amplify, { Auth, Storage } from 'aws-amplify';

import Compress from 'react-image-file-resizer';
import axios from '../axios';
import awsExports from '../aws-exports';

Amplify.configure(awsExports);

export async function generateS3Object(key, level) {
  const region = awsExports.aws_user_files_s3_bucket_region;
  const bucket = awsExports.aws_user_files_s3_bucket;

  const credentials = await Auth.currentUserCredentials();
  const { identityId } = credentials;
  return {
    bucket,
    region,
    key,
    level,
    identityId,
  };
}

export async function uploadViolationImage(image, uuid) {
  try {
    await Storage.put(`violation/${uuid}/main`, image, {
      level: 'protected',
    });

    return generateS3Object(`violation/${uuid}/main`, 'protected');
  } catch (err) {
    console.log(err);
  }
}

export async function getS3SignedUrl({ key, level }) {
  return Storage.get(key, {
    level,
  });
}

export async function getContractSignedURL(rentalID, contractID) {
  return Storage.get(`contracts/${rentalID}/${contractID}.docx`, {
    level: 'private',
  });
}

// returns url
export async function upload(file, category) {
  if (file === undefined) {
    throw new Error('Upload file is empty');
  }
  const formData = new FormData();

  formData.append('file', file);
  formData.append('category', category);
  return axios.post('/uploadFile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

// Returns storage object
export async function uploadSecure(file, category) {
  if (file === undefined) {
    throw new Error('Upload file is empty');
  }
  const formData = new FormData();

  formData.append('file', file);
  formData.append('category', category);
  return axios.post('/uploadFileSecure', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export const convertToWebp = async (file) => {
  return new Promise((resolve) => {
    Compress.imageFileResizer(
      file,
      300, // width
      300, // height
      'WEBP', // compress format WEBP, JPEG, PNG
      100, // quality
      0, // rotation
      (r) => {
        return resolve(r);
      },
      'blob' // blob or base64 default base64
    );
  });
};

export async function uploadImage(file, compress, category) {
  if (file === undefined) {
    return null;
  }
  const fileData = compress ? await convertToWebp(file) : file;
  return upload(fileData, category);
}

export const Category = {
  applicationSample: 'APPLICATION_SAMPLES',
  profileImage: 'PROFILE_IMAGE',
  rentalImage: 'RENTAL_IMAGE',
  applicationImage: 'APPLICATION_IMAGE',
  applicationDoc: 'APPLICATION_DOC',
  violationImage: 'VIOLATION_IMAGE',
};
