import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import get from 'lodash/get';

import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';

import Button from 'components/BaseComponents/Button';

import { signIn } from 'api/auth';
import { LOGIN_TYPE } from 'enums/loginType';
import { RoutesNames } from 'routes/RoutesNames';
import { resendResetPasswordLink } from 'api/services/authServices';

import { PhoneInput } from '../../../PhoneInput';
import { SelectRegisterType } from '../../../SelectRegisterType';
import { axiosUnauth } from '../../../../../axios';

import { useStyles, StyledErrorMessage, SFeild } from '../resetPassword.style';
import { requestResetPassValidationSchema as validationSchema } from '../resetPassword.validation';

export default function GetEmailForm({ goNext, setUsername: onSetUserName }) {
  const classes = useStyles();
  const history = useHistory();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [loginType, setLoginType] = useState('email');
  const submitBtnRef = useRef();
  const logInRef = useRef();

  async function resendForTmpUser(username) {
    try {
      // None authenticated call
      await axiosUnauth.patch('/tenant/auth/resend', {
        username,
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  async function resendForUnconfirmedUser(username) {
    try {
      await signIn({
        username,
        password: '123',
      });
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        history.push(RoutesNames.Confirmation, {
          username,
          resetPassword: true,
        });
      }
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    const { phone, email } = values;
    const username = email ? email.toLowerCase() : `+${phone}`;
    try {
      const response = await resendResetPasswordLink({
        data: { [email ? 'email' : 'phone']: username },
      });
      onSetUserName(username);
      if (response.status === 'resentCode') {
        return goNext(1);
      }
      goNext(2);
    } catch (e) {
      setError(get(e, 'response.data', e));
      if (e.code === 'NotAuthorizedException' && e.message === 'User password cannot be reset in the current state.') {
        goNext(2);
        await resendForTmpUser(username);
      } else if (
        e.code === 'InvalidParameterException' &&
        e.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number'
      ) {
        await resendForUnconfirmedUser(username);
      } else {
        console.log(e);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SelectRegisterType
        label='Reset using'
        initialValue={loginType}
        onSelect={(type) => {
          setError();
          setLoginType(type);
          logInRef?.current.resetForm();
        }}
      />
      <Formik
        innerRef={logInRef}
        onSubmit={handleSubmit}
        initialValues={{ phone: '', email: '' }}
        validationSchema={validationSchema(loginType)}
      >
        {({ errors, touched, values, setValues }) => (
          <Form>
            <Grid container spacing={2}>
              {loginType === LOGIN_TYPE.EMAIL ? (
                <>
                  <Grid item xs={12}>
                    <FormLabel component='legend'>Email</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <SFeild
                      id='email'
                      name='email'
                      data-cy='username'
                      placeholder='Email'
                      autoComplete='email'
                      errors={touched.email && errors.email}
                      invalid={touched.email && errors.email}
                      onChange={(e) => setValues({ ...values, phone: '', email: e.target.value })}
                    />
                  </Grid>
                </>
              ) : (
                <PhoneInput
                  data-cy='username'
                  initialValue={values.phone}
                  errorMessage={touched.phone && errors.phone}
                  onTabKey={() => submitBtnRef.current.focus()}
                  onChangeHandler={(value) => {
                    setValues({ ...values, phone: value, email: '' }, true);
                  }}
                />
              )}
              <Grid item xs={12}>
                <div className={classes.btnWrapper}>
                  <Button
                    type='submit'
                    loading={loading}
                    disabled={loading}
                    innerRef={submitBtnRef}
                    className={classes.onboardingBtn}
                  >
                    Submit
                  </Button>
                </div>
                {error && (
                  <div className={classes.smallErrorMessageWrapper}>
                    <StyledErrorMessage>
                      {error.code === 'UserNotFoundException' ? 'Provided username is invalid' : error.message}
                    </StyledErrorMessage>
                  </div>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
