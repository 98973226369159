import React, { useState, cloneElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useMediaQuery } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import SignUpIntroduction from './components/AuthComponents/SignUpIntroduction';
import SignUpIntroductionMobile from './components/AuthComponents/SignUpIntroduction/introduction.mobile';

import { useStyles } from './routes.style';

export default function Portal({ children }) {
  const [accountType, setAccountType] = useState('landlord');
  const matches = useMediaQuery('(max-width: 960px)');
  const classes = useStyles();

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      {matches ? (
        <Grid item xs={12} sm={false} className={classes.welcome}>
          <SignUpIntroductionMobile accountType={accountType} />
        </Grid>
      ) : (
        <Grid item xs={false} sm={6} className={classes.welcome}>
          <SignUpIntroduction accountType={accountType} />
        </Grid>
      )}
      <Grid item xs={12} md={6} component={Paper} className={classes.formPanel}>
        <div className={classes.paper}>{cloneElement(children, { accountType, setAccountType })}</div>
      </Grid>
    </Grid>
  );
}
