import React from 'react';

import { LoginProvider } from 'contexts/LoginContext';
import LoginForm from './login.form';

export default function Login() {
  return (
    <LoginProvider>
      <LoginForm />
    </LoginProvider>
  );
}
