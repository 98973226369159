import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { makeStyles, styled as MUIStyled } from '@material-ui/core/styles';

import theme from 'components/theme';
import TextField from 'components/BaseComponents/TextField';

export const useStyles = makeStyles((theme) => ({
  titleSmall: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
  },
  body1: {
    fontWeight: 500,
  },
  resend: {
    display: 'inline-block',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: '16px',
  },
  onboardingBtn: {
    marginRight: '10px',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0 18px',
  },
  onboardingLink: {
    fontWeight: 800,
    '& a': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.primarycolor,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
  },
  smallErrorMessageWrapper: {
    height: '21px',
    marginTop: '-16px',
  },
  errorMessageWrapper: {
    height: '42px',
    marginTop: '-16px',
  },
  innerForm: {
    marginTop: 16,
  },
}));

export const StyledErrorMessage = styled.p`
  color: ${theme.errorColor};
  font-weight: 500;
`;

export const SFeild = MUIStyled(({ as, ...rest }) => (
  <Field fullWidth as={as || TextField} margin='normal' variant='outlined' {...rest} />
))({
  margin: '-8px 0 8px',
  '& + p': {
    top: 32,
  },
});
