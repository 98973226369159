import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: ({ nomargin }) => (nomargin ? '8px 0 8px' : '8px 0 24px'),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: ({ invalid }) => (invalid ? theme.errorColor : theme.palette.primary.main),
      borderRadius: '8px',
    },
    '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.secondaryTextColor,
    },
  },
  errorMessage: {
    color: theme.errorColor,
    position: 'absolute',
    top: ({ rows }) => (rows ? (rows + 1) * 25 : 50),
    whiteSpace: ({ noWrap }) => (noWrap ? 'nowrap' : ''),
    fontSize: 14,
  },
  missing: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F6911A !important',
    },
  },
}));
