import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import get from 'lodash/get';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from 'components/BaseComponents/Button';
import ModifiedRadio from 'components/BaseComponents/Radio';

import { RoutesNames } from 'routes/RoutesNames';
import { useSignUp } from 'api/apiHooks/authHooks';

import { PhoneInput } from '../../PhoneInput';
import { validationSchema } from './signUp.validation';
import { errorMessageEnums, getUserSignUpRequestData } from './helpers';
import { useStyles, StyledLoginMessage, SFeild, STermsLink, STerm, STermCheckBox, STermLabel } from './signUp.style';

// TODO Refactore this component
export default function SignUp({ accountType, setAccountType }) {
  const classes = useStyles();
  const history = useHistory();
  const logInRef = useRef();
  const passwordRef = useRef();

  const [error, setError] = useState(null);
  const [registerUsing, setRegisterUsing] = useState('email');

  const { refetch: signUp, loading } = useSignUp(
    (data) => {
      history.push(RoutesNames.Confirmation, {
        username: data.username,
      });
    },
    (e) => {
      const message = get(e, ['response', 'data', 'message']);
      if (!message) {
        setError(null);
        return;
      }

      if (message.includes('already exists')) {
        setError({
          code: 'UsernameExistsException',
          message: message.includes('email') ? errorMessageEnums.emailExists : errorMessageEnums.phoneExists,
        });
      } else {
        setError({ message });
      }
    }
  );

  const handleSubmit = (values) => {
    if (!accountType) {
      setError({
        code: 'accountTypeError',
        message: 'Please select an account type above.',
      });
      return;
    }

    const { fullName, businessName, password, email, phone, haveAnimal, roommateHasAnimal } = values;
    const userToCreate = {
      accountType,
      name: fullName,
      businessName,
      email,
      password,
      haveAnimal,
      roommateHasAnimal: haveAnimal === 'yes' ? 'notSelected' : roommateHasAnimal,
      phone: phone ? `+${phone}` : null,
    };

    signUp({ data: getUserSignUpRequestData(userToCreate) });
  };

  return (
    <>
      <Container maxWidth='xs'>
        <Typography component='h2' variant='h2' className={classes.titleSmall}>
          Let&apos;s get started
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component='legend'>Select your account type</FormLabel>
          </Grid>
          <RadioGroup
            className={classes.radioBlock}
            row
            value={accountType}
            onChange={(_, newValue) => {
              setAccountType(newValue);
              logInRef.current?.setTouched({});
              if (newValue === 'landlord') {
                setRegisterUsing('email');
              }
            }}
          >
            <Grid item xs={6}>
              <FormControlLabel
                value='landlord'
                label='Landlord'
                data-cy='accountLandlord'
                control={<ModifiedRadio />}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel value='tenant' control={<ModifiedRadio />} label='Tenant' data-cy='accountTenant' />
            </Grid>
          </RadioGroup>
          {accountType !== 'landlord' && (
            <>
              <Grid item xs={12}>
                <FormLabel>Register using</FormLabel>
              </Grid>
              <RadioGroup
                className={classes.radioBlock}
                row
                value={registerUsing}
                onChange={(_, newValue) => {
                  setRegisterUsing(newValue);
                }}
              >
                <Grid item xs={6}>
                  <FormControlLabel value='email' control={<ModifiedRadio />} label='Email' data-cy='registerEmail' />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel value='phone' control={<ModifiedRadio />} label='Phone' data-cy='registerPhone' />
                </Grid>
              </RadioGroup>
            </>
          )}
        </Grid>

        <Formik
          innerRef={logInRef}
          validateOnChange
          initialValues={{
            fullName: '',
            businessName: '',
            haveAnimal: '',
            roommateHasAnimal: '',
            email: '',
            password: '',
            confirm_password: '',
            phone: '',
            terms: false,
          }}
          validationSchema={() => validationSchema(registerUsing, accountType)}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className={classes.form}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormLabel component='legend'>
                    Full Name{`${accountType !== 'landlord' ? '/ Business Name' : ''}`}
                  </FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SFeild
                    id='fullName'
                    testid='name'
                    name='fullName'
                    data-cy='fullName'
                    autoComplete='fullName'
                    placeholder='e.g. John Doe'
                    errors={touched.fullName && errors.fullName}
                    invalid={touched.fullName && errors.fullName}
                    wrapperClassName={classes.passwordFiledWrapper}
                  />
                </Grid>
                {accountType === 'landlord' && (
                  <>
                    <Grid item xs={12}>
                      <FormLabel component='legend'>Business Name</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SFeild
                        id='businessName'
                        name='businessName'
                        data-cy='businessName'
                        autoComplete='businessName'
                        placeholder='e.g. John Doe'
                        errors={touched.businessName && errors.businessName}
                        invalid={touched.businessName && errors.businessName}
                        wrapperClassName={classes.passwordFiledWrapper}
                      />
                    </Grid>
                  </>
                )}

                {registerUsing === 'email' && (
                  <>
                    <Grid item xs={12}>
                      <FormLabel component='legend'>Email</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SFeild
                        id='email'
                        name='email'
                        testid='email'
                        data-cy='email'
                        placeholder='Email'
                        autoComplete='email'
                        errors={touched.email && errors.email}
                        invalid={touched.email && errors.email}
                        wrapperClassName={classes.passwordFiledWrapper}
                        onChange={(e) => {
                          setFieldValue('email', e.target.value);
                          if (accountType !== 'landlord') {
                            setFieldValue('phone', '');
                          }
                        }}
                      />
                    </Grid>
                  </>
                )}
                {(accountType === 'landlord' || registerUsing === 'phone') && (
                  <PhoneInput
                    label={accountType === 'landlord' ? 'Phone number (optional)' : 'Phone number'}
                    initialValue={values.phone}
                    errorMessage={touched.phone && errors.phone}
                    onTabKey={() => passwordRef.current.focus()}
                    onChangeHandler={(value) => {
                      setFieldValue('phone', value);
                      if (accountType !== 'landlord') {
                        setFieldValue('email', '');
                      }
                    }}
                  />
                )}

                <Grid item xs={12}>
                  <FormLabel component='legend'>New password (Min. of 6 characters)</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SFeild
                    type='password'
                    name='password'
                    testid='password'
                    data-cy='password'
                    placeholder='Password'
                    errors={touched.password && errors.password}
                    invalid={touched.password && errors.password}
                    wrapperClassName={classes.passwordFiledWrapper}
                    inputProps={{ ref: passwordRef }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel component='legend'>Repeat the password</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SFeild
                    type='password'
                    testid='confirm'
                    name='confirm_password'
                    data-cy='confirmPassword'
                    placeholder='Repeat the password'
                    errors={touched.confirm_password && errors.confirm_password}
                    invalid={touched.confirm_password && errors.confirm_password}
                    wrapperClassName={classes.passwordFiledWrapper}
                  />
                </Grid>

                {accountType === 'tenant' && (
                  <>
                    <Grid item xs={12}>
                      <FormLabel component='legend'>Do you have an animal?</FormLabel>
                    </Grid>
                    <FormControl
                      component='fieldset'
                      className={clsx({ [classes.invalidAnimalRadio]: touched.haveAnimal && errors.haveAnimal })}
                    >
                      <RadioGroup
                        className={classes.radioBlock}
                        row
                        value={values.haveAnimal}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue('haveAnimal', value);
                        }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel value='yes' control={<ModifiedRadio />} label='Yes' />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel value='no' control={<ModifiedRadio />} label='No' />
                        </Grid>
                      </RadioGroup>
                    </FormControl>
                    {values.haveAnimal === 'no' && (
                      <>
                        <Grid item xs={12}>
                          <FormLabel component='legend'>
                            Does anyone else in the unit have an animal (i.e. roommate)?
                          </FormLabel>
                        </Grid>
                        <FormControl
                          component='fieldset'
                          className={clsx([
                            { [classes.invalidAnimalRadio]: touched.roommateHasAnimal && errors.roommateHasAnimal },
                          ])}
                        >
                          <RadioGroup
                            className={classes.radioBlock}
                            row
                            value={values.roommateHasAnimal}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue('roommateHasAnimal', value);
                            }}
                          >
                            <Grid item xs={6}>
                              <FormControlLabel value='yes' control={<ModifiedRadio />} label='Yes' />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel value='no' control={<ModifiedRadio />} label='No' />
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <STermLabel
                    data-cy='accept'
                    data-testid='accept'
                    control={<STermCheckBox />}
                    className={clsx([{ [classes.invalid]: touched.terms && errors.terms }])}
                    label={
                      <STerm>
                        I accept the{' '}
                        <STermsLink href='https://ourpetpolicy.com/terms-and-conditions/'>
                          Terms and Conditions
                        </STermsLink>{' '}
                        and <STermsLink href='https://ourpetpolicy.com/privacy-policy/'>Privacy Policy</STermsLink>
                      </STerm>
                    }
                  />
                </Grid>
              </Grid>

              <div className={classes.btnWrapper}>
                <Button type='submit' disabled={loading} loading={loading} data-testid='sign-up-btn' data-cy='signUp'>
                  Sign up
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <Grid container>
          <Grid item className={classes.onboardingLink}>
            {error && ['UsernameExistsException', 'accountTypeError'].includes(error.code) && (
              <div className={classes.smallErrorMessageWrapper}>
                <StyledLoginMessage>{error.message}</StyledLoginMessage>
              </div>
            )}

            <div className='login_link'>
              Already have an account? <Link onClick={() => history.push('/')}>Login here</Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
