import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import camelCase from 'lodash/camelCase';
import { ThemeProvider } from '@material-ui/core/styles';

import { useGetPaymentMethod } from 'api/apiHooks/paymentHooks';
import { useGetUserByType, useGetUserByUsername } from 'api/apiHooks/userHooks';
import theme from './components/theme';
import { GlobalStyle } from './GlobalStyles';
import ErrorBoundary from './ErrorBoundry';
import PublicRoute from './routes/PublicRoutes';
import PrivateRoute from './routes/PrivateRoutes';
import { RoutesData } from './routes/RoutesData';
import { useAppDispatch, useAppState } from './contexts/PropertyEditContext';
import { userRoles, userRequestBaseUrl } from './utils/enums';
import axios from './axios';
import { initialize } from './googleAnalytics';
import { getHasLandlordPermissions } from './utils/helpers';

initialize();

function App() {
  const { profile } = useAppState();
  const dispatch = useAppDispatch();

  const { refetch: getUserByType } = useGetUserByType((data) => {
    const impersonate = JSON.parse(localStorage.getItem('impersonate'));

    if (data.type === userRoles.ADMIN) {
      dispatch({
        type: 'SET_ADMIN',
        payload: data,
      });

      if (impersonate && !profile) {
        getUserByType({ baseUrl: userRequestBaseUrl[impersonate.type], userId: impersonate.id });
        return;
      }
    }

    dispatch({
      type: 'SET_PROFILE',
      payload: data,
    });
    dispatch({
      type: 'SET_IMPERSONATE',
      payload: impersonate ? data : null,
    });
  });

  const { refetch: getUserByUsername } = useGetUserByUsername((data) => {
    localStorage.setItem('userId', data.id);
    getUserByType({ baseUrl: userRequestBaseUrl[data.type], userId: data.id });
  });

  async function getCurrentAuthenticatedUser() {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        const userType = camelCase(user.attributes['custom:userType'].split(' ')[0]);
        const username = user.attributes.email || user.attributes.phone_number;
        const userId = JSON.parse(localStorage.getItem('userId'));

        if (userType && userId) {
          getUserByType({ baseUrl: userRequestBaseUrl[userType], userId });
          return;
        }

        if (username) {
          getUserByUsername({ username });
          return;
        }
        localStorage.clear();
      })
      .catch(() => {
        const user = localStorage.getItem('user');

        if (user) {
          localStorage.clear();
          window.location.reload();
        }
      });
  }

  const { refetch: getPaymentInfo } = useGetPaymentMethod((data) => {
    dispatch({ type: 'SET_CARD', payload: data });
  });

  useEffect(() => {
    function gtag() {
      window.dataLayer.push(arguments);
    }
    if (process.env.REACT_APP_HOSTNAME === 'https://app.ourpetpolicy.com') {
      window.dataLayer = window.dataLayer || [];

      gtag('js', new Date());

      gtag('config', 'G-YE5YW0JR63');
    }
  }, []);

  useEffect(() => {
    if (profile) {
      return;
    }

    getCurrentAuthenticatedUser();
  }, [profile]);

  useEffect(() => {
    if ([userRoles.LANDLORD, userRoles.TENANT].includes(profile?.type)) {
      getPaymentInfo({ userType: profile.type, params: { [`${profile.type}Id`]: profile.id } });
    }
  }, [profile, dispatch]);

  // TODO: Remove after onboarding refactor
  useEffect(() => {
    if (profile && getHasLandlordPermissions(profile.type)) {
      (async () => {
        const fetchedPolicies = await axios.get('/policy', {
          params: {
            size: 1000,
          },
        });
        dispatch({
          type: 'ADD_POLICIES',
          payload: {
            policies: fetchedPolicies.data.content,
          },
        });
      })();
    }
  }, [profile, dispatch]);

  return (
    <ErrorBoundary profile={profile}>
      <Router>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <Switch>
            {RoutesData.map((route, index) => {
              const { path, exact, component, allowedUserTypes, isPrivate } = route;
              return isPrivate ? (
                <PrivateRoute
                  path={path}
                  exact={exact}
                  component={component}
                  allowedUserTypes={allowedUserTypes}
                  key={index.toString()}
                />
              ) : (
                <PublicRoute path={path} exact={exact} component={component} key={index.toString()} />
              );
            })}
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </ThemeProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
