import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import theme from 'components/theme';

export const useStyles = makeStyles((theme) => ({
  titleSmall: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
  },
  invalid: {
    color: theme.errorColor,
    '& .MuiCheckbox-root': {
      color: theme.errorColor,
    },
  },
  onboardingLink: {
    marginBottom: theme.spacing(1),
    fontWeight: 800,
    '& a': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.primarycolor,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
  },
  errorMessageWrapper: {
    height: '42px',
    marginTop: '-16px',
  },
}));

export const StyledErrorMessage = styled.p`
  color: ${theme.errorColor};
  font-weight: 500;
`;

export const FormWrapper = styled.div`
  width: 448px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
