import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import camelCase from 'lodash/camelCase';

import SidebarRoutes from './SidebarRoutes';
import RedirectToDefault from './components/RedirectToDefault';
import { defaultPagePath } from '../utils/enums';

const PrivateRoute = ({ component: Component, allowedUserTypes, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const showIntro = user?.attributes['custom:showIntro'];
  const impersonate = JSON.parse(localStorage.getItem('impersonate'));
  const { type } = impersonate || {};
  const userType = type || camelCase(user?.attributes['custom:userType'].split(' ')[0]);

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          allowedUserTypes.indexOf(userType) !== -1 ? (
            <SidebarRoutes>
              <Component {...props} />
            </SidebarRoutes>
          ) : (
            <Redirect to={defaultPagePath[userType][showIntro]} />
          )
        ) : (
          <RedirectToDefault />
        )
      }
    />
  );
};

export default PrivateRoute;
