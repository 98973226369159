import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';

import useCanCreateContract from 'components/Hooks/useCanCreateContract';

import SubMenu from './SubMenu';
import { CustomTooltip } from './CustomTooltip';

const LinkTab = ({ label, isSelected, route, subRoutes, dataCy, badge, ...restProps }) => {
  const location = useLocation();
  const pathname = route[0] === '/' ? route : `/${route}`;
  const isTabSelected = isSelected || subRoutes?.some((item) => location.pathname.includes(item.route));
  const { root, activeState } = restProps;
  const canCreateContract = useCanCreateContract();

  const LinkWrapper = forwardRef((props, ref) => (
    <div>
      <CustomTooltip route={route} badge={badge}>
        <Link to={activeState === 'intro' ? '/intro' : pathname} {...props} ref={ref} />
      </CustomTooltip>
      {isTabSelected && <SubMenu routes={subRoutes} />}
    </div>
  ));

  LinkWrapper.displayName = 'LinkWrapper';
  return (
    <Tab
      data-cy={dataCy}
      label={label}
      className={isTabSelected ? 'dashboard-tabs_active' : 'dashboard-tabs'}
      classes={{ root }}
      component={LinkWrapper}
      disabled={!!(!canCreateContract && route === 'contracts')}
      {...restProps}
    />
  );
};

export default LinkTab;
