import { useApiResult } from './abstractApiHooks';
import {
  getUserByTypeService,
  getUserByUsernameService,
  getUserService,
  updateFullNameService,
  updateProfileImageService,
  updateUserStatusService,
} from '../services/userServices';

export const useGetUser = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(getUserService, onSuccess, onError);
};

export const useGetUserByUsername = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(getUserByUsernameService, onSuccess, onError);
};

export const useGetUserByType = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(getUserByTypeService, onSuccess, onError);
};

export const useUpdateUserStatus = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateUserStatusService, onSuccess, onError);
};

export const useUpdateProfileImage = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateProfileImageService, onSuccess, onError);
};

export const useUpdateFullName = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updateFullNameService, onSuccess, onError);
};
