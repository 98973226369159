import {
  addPaymentMethodService,
  getPaymentMethodService,
  updatePaymentMethodService,
  updatePaymentMethodByTenantService,
} from 'api/services/paymentServices';
import { useApiResult } from './abstractApiHooks';

export const useAddPaymentMethod = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(addPaymentMethodService, onSuccess, onError);
};

export const useGetPaymentMethod = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(getPaymentMethodService, onSuccess, onError);
};

export const useUpdatePaymentMethod = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updatePaymentMethodService, onSuccess, onError);
};

export const useUpdatePaymentMethodByTenant = (onSuccess = () => {}, onError = () => {}) => {
  return useApiResult(updatePaymentMethodByTenantService, onSuccess, onError);
};
