import { object, string, ref } from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const validationSchema = object({
  code: string().required('Code is required'),
  confirm_password: string()
    .oneOf([ref('password'), null], 'Passwords mismatch')
    .required('Confirm Passwords is required'),
  password: string().required('Passwords is required').min(6, 'The password should contain min. 6 characters.'),
});

export const requestResetPassValidationSchema = (registerUsing) => {
  const validationObject = {};

  if (registerUsing === 'phone') {
    const phoneRegExp = /^(\d{11}|\d{12})$/;
    validationObject.phone = string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .test('Phone number test', 'Phone number is not valid', (value) => isValidPhoneNumber(`+${value}`));
    delete validationObject.email;
  } else {
    validationObject.email = string().email('Invalid Email Address').required('Email is required');
    delete validationObject.phone;
  }

  return object(validationObject);
};
