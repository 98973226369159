import React, { forwardRef, memo, useImperativeHandle } from 'react';
import { useLocation } from 'react-router-dom';
import camelCase from 'lodash/camelCase';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';

import { TabValues } from 'routes/RoutesNames';
import { useAppState } from 'contexts/PropertyEditContext';

import theme from 'components/theme';

import LinkTab from './components/LinkTab';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { tabList } from './components/TabList';

import { useStyles, TabsWrapper, Drawer } from './sidbar.style';

const VerticalTabs = forwardRef(({ sideBarOpen, onClose, onLogout }, ref) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { impersonate, profile } = useAppState();
  const matches = useMediaQuery('(max-width:768px)');

  const tabValue = TabValues[`/${pathname.split('/')[1]}`];
  const user = JSON.parse(localStorage.getItem('user'));
  const userType = camelCase(user.attributes['custom:userType'].split(' ')[0]);
  const tabListData = tabList({ impersonate, profile });

  useImperativeHandle(ref, () => ({
    isSideBarExist: () => !(tabValue === 'intro' || matches),
  }));

  if (tabValue === 'intro') {
    return;
  }

  return (
    <Drawer
      anchor='left'
      open={matches ? sideBarOpen : true}
      onClose={onClose}
      variant={matches ? 'temporary' : 'permanent'}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Header onClose={onClose} />
      <TabsWrapper>
        <Tabs
          orientation='vertical'
          classes={{
            indicator: classes.indicator,
          }}
          value={
            tabListData[impersonate ? impersonate.type : userType].some((routeData) => routeData.route === tabValue)
              ? tabValue
              : false
          }
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          {tabListData[impersonate ? impersonate.type : userType].map(({ label, icon, route, subRoutes, dataCy }) => {
            return (
              <LinkTab
                key={label}
                label={
                  <Typography className={classes.tabText} component='p' variant='body1'>
                    {label}
                  </Typography>
                }
                value={route}
                route={route}
                subRoutes={subRoutes || []}
                icon={icon}
                isSelected={pathname.includes(route)}
                dataCy={dataCy}
                root={classes.root}
                badge={classes.badge}
              />
            );
          })}
        </Tabs>
      </TabsWrapper>
      {matches && <Footer onClose={onClose} onLogout={onLogout} />}
    </Drawer>
  );
});

export default memo(VerticalTabs);
