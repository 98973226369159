import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    position: 'relative',
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
  },
  root: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
    textTransform: 'none',
    position: 'relative',
    borderRadius: theme.palette.shape.buttonBorderRadius,
    color: '#0D0D0D',
    fontWeight: 500,
    fontSize: '14px',
    border: ({ borderType, borderColor }) => `${borderType} ${borderColor}`,
    background: ({ background }) => background,
    padding: ({ padding, version }) => padding || (version === 'white' ? '5px 30px' : '3px 30px'),
    '@media (min-width: 768px) and (max-width: 1280px)': {
      fontSize: '14px',
    },
    '@media (max-width: 768px)': {
      fontSize: '14px',
      width: '100%',
    },
    '& svg': {
      marginRight: 12,
    },
    '&.MuiButtonBase-root:hover': {
      background: ({ version }) =>
        version === 'secondary'
          ? theme.palette.primary.main
          : version === 'bgWhite'
          ? '#EFF3F5'
          : theme.palette.primary.hover,
      borderColor: ({ border }) => (border === 'secondary' ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.hover),
    },
    '&$disabled': {
      background: theme.disableColor,
      borderColor: theme.disableColor,
      color: theme.disableTextColor,
      '& svg': {
        color: theme.disableTextColor,
      },
    },
    '&$contained': {
      boxShadow: 'none',
      margin: 0,
    },
  },
  disabled: {},
  contained: {},
}));
