import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import camelCase from 'lodash/camelCase';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { LOGIN_FAILURE_MESSAGE, LOGIN_TYPE } from 'enums/loginType';
import Button from 'components/BaseComponents/Button';
import Loader from 'components/BaseComponents/Loader';
import { useQuery } from 'components/Hooks/useQuery';

import { signIn } from 'api/auth';
import { defaultPagePath, userRequestBaseUrl, userRoles } from 'utils/enums';
import { RoutesNames } from 'routes/RoutesNames';
import { useLoginContext } from 'contexts/LoginContext';
import { useAppDispatch, useAppState } from 'contexts/PropertyEditContext';
import { useResendResetPasswordLink } from 'api/apiHooks/authHooks';
import { useGetUserByType } from 'api/apiHooks/userHooks';

import { validationSchema } from './login.validation';
import { useStyles, StyledErrorMessage, SFeild, SWrapper } from './login.style';

import { PhoneInput } from '../../PhoneInput';
import { SelectRegisterType } from '../../SelectRegisterType';

export default function LoginForm() {
  const classes = useStyles();
  const logInRef = useRef();
  const passwordRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const searchParams = useQuery();
  const username = searchParams.get('u');
  const password = searchParams.get('p');

  const { lastPosition } = useAppState();
  const appDispatch = useAppDispatch();
  const [state, dispatch] = useLoginContext();

  const { loading, loginError } = state;
  const [loginType, setLoginType] = useState('email');
  const [redirectLoading, setRedirectLoading] = useState(false);

  const { refetch: resendPasswordLink } = useResendResetPasswordLink(
    () => {
      history.push({
        pathname: RoutesNames.ResetPassword,
        defaultStep: 1,
      });
    },
    (e) => {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: e.response.data.message,
        },
      });
      dispatch({
        type: 'CLEAR_LOADING',
      });
    }
  );

  const { refetch: getUserByType } = useGetUserByType(
    (data) => {
      const awsUser = JSON.parse(localStorage.getItem('user'));
      const userType = camelCase(awsUser.attributes['custom:userType'].split(' ')[0]);
      const showIntro = awsUser.attributes['custom:showIntro'];
      appDispatch({
        type: 'SET_PROFILE',
        payload: data,
      });
      if (data.type === userRoles.ADMIN) {
        appDispatch({
          type: 'SET_ADMIN',
          payload: data,
        });
      }

      history.push(lastPosition || defaultPagePath[userType][showIntro]);
      appDispatch({ type: 'SET_LASTPOSITION', payload: null });
      appDispatch({
        type: 'CLEAR_LOADING',
      });
      setRedirectLoading(false);
    },
    (error) => {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          error: error.message,
        },
      });
    }
  );

  const clearQueryUsernamePassword = () => {
    searchParams.delete('u');
    searchParams.delete('p');
    history.replace(location.pathname, { search: searchParams.toString() });
  };

  async function handleSubmit(values) {
    dispatch({
      type: 'SET_LOADING',
    });
    const { email, phone, username: redirectedUsername, password } = values;
    let username = redirectedUsername || (loginType === 'email' ? email : phone);

    if (redirectedUsername) {
      clearQueryUsernamePassword();
    }

    if (loginType === 'phone') {
      if (username.startsWith('00')) {
        username = `+${username.slice(2)}`;
      }
      if (!username.startsWith('+')) {
        username = `+${username}`;
      }
    }

    try {
      const { awsUser, user } = await signIn({
        username: `${username}`.trim().toLowerCase(),
        password,
      });
      if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        history.push({
          pathname: RoutesNames.ResentTenantPassword,
          username: `${username}`.trim().toLowerCase(),
          sentPassword: password,
        });
      }

      const roles = awsUser.attributes['custom:userType'];
      const userType = camelCase(roles.split(' ')[0]);
      const showIntro = awsUser.attributes['custom:showIntro'];
      getUserByType({ baseUrl: userRequestBaseUrl[userType], userId: user.id });
    } catch (e) {
      if (e.code === 'PasswordResetRequiredException') {
        resendPasswordLink({
          data: { [loginType]: username },
        });
        return;
      }
      if (e.message === 'User is not confirmed.') {
        history.push(RoutesNames.Confirmation, {
          username,
        });
      }
      dispatch({
        type: 'SET_ERROR',
        payload: {
          username,
          error: LOGIN_FAILURE_MESSAGE[e.message] || e.message,
        },
      });
      appDispatch({
        type: 'CLEAR_LOADING',
      });
      setRedirectLoading(false);
    }
  }

  useEffect(() => {
    if (username && password) {
      setRedirectLoading(true);
      let decodedPassword;

      try {
        decodedPassword = window.atob(password);
      } catch (e) {
        clearQueryUsernamePassword();
        dispatch({
          type: 'SET_ERROR',
          payload: {
            error: 'Please try again!',
          },
        });
        setRedirectLoading(false);
        return;
      }

      handleSubmit({ username, password: decodedPassword });
    }
  }, []);

  if ((username && password) || redirectLoading) {
    return (
      <SWrapper>
        <Loader loading />
      </SWrapper>
    );
  }

  return (
    <>
      <Container maxWidth='xs'>
        <Typography component='h2' variant='h2' className={classes.titleSmall}>
          Welcome back!
        </Typography>
        <Typography component='p' variant='body1' className={classes.subtitleSmall}>
          Enter your credentials to login
        </Typography>

        <SelectRegisterType
          label='Login using'
          initialValue={loginType}
          onSelect={(type) => {
            setLoginType(type);
            dispatch({ type: 'CLEAR_ERROR' });
            logInRef?.current.resetForm();
          }}
        />

        <Formik
          innerRef={logInRef}
          validateOnChange={false}
          initialValues={{ phone: '', email: '', password: '' }}
          validationSchema={validationSchema(loginType)}
          onSubmit={async (values) => {
            dispatch({ type: 'CLEAR_ERROR' });
            await handleSubmit(values);
          }}
        >
          {({ errors, touched, values, setValues }) => (
            <Form>
              <Grid container spacing={2}>
                {loginType === LOGIN_TYPE.EMAIL ? (
                  <>
                    <Grid item xs={12}>
                      <FormLabel component='legend'>Email</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <SFeild
                        id='email'
                        name='email'
                        data-cy='username'
                        placeholder='Email'
                        autoComplete='email'
                        errors={touched.email && errors.email}
                        invalid={touched.email && errors.email}
                        onChange={(e) => setValues({ ...values, phone: '', email: e.target.value })}
                      />
                    </Grid>
                  </>
                ) : (
                  <PhoneInput
                    data-cy='username'
                    initialValue={values.phone}
                    errorMessage={touched.phone && errors.phone}
                    onTabKey={() => passwordRef.current.focus()}
                    onChangeHandler={(value) => {
                      setValues({ ...values, phone: value, email: '' }, true);
                    }}
                  />
                )}
                <Grid item xs={12}>
                  <FormLabel component='legend'>Password</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <SFeild
                    name='password'
                    type='password'
                    data-cy='password'
                    autoComplete='password'
                    placeholder='Password'
                    errors={touched.password && errors.password}
                    invalid={touched.password && errors.password}
                    inputProps={{ ref: passwordRef }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.btnWrapper}>
                    <Button type='submit' disabled={loading} loading={loading} className={classes.onboardingBtn}>
                      Login
                    </Button>
                  </div>
                </Grid>
              </Grid>
              {loginError && <StyledErrorMessage>{loginError}</StyledErrorMessage>}
            </Form>
          )}
        </Formik>
        <div className={classes.onboardingLink}>
          Forgot password? <Link onClick={() => history.push(RoutesNames.ResetPassword)}>Reset it here</Link>
        </div>
        <div className={classes.onboardingLink}>
          Don&apos;t have an account?{' '}
          <Link onClick={() => history.push(RoutesNames.SignUp)} data-testid='sign-up-btn'>
            Sign up here
          </Link>
        </div>
      </Container>
    </>
  );
}
