import axios from 'axios';
import { Auth } from 'aws-amplify';
import get from 'lodash/get';

axios.defaults.withCredentials = true;
const baseURL = `${process.env.REACT_APP_HOSTNAME}/api/v1/`;

const instance = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

const isTokenExpired = (expiration) => new Date().getTime() / 1000 > expiration;

instance.interceptors.request.use(async function (config) {
  const res = { ...config };
  const session = await Auth.currentSession();
  const { jwtToken, payload } = session.idToken;
  res.headers.Authorization = jwtToken ? `Bearer ${jwtToken}` : '';
  if (isTokenExpired(payload.exp || '')) {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    cognitoUser.refreshSession(get(session, 'refreshToken'), (err, userSession) => {
      const token = get(userSession, 'idToken.jwtToken', '');
      res.headers.Authorization = token ? `Bearer ${token}` : '';
    });
  }
  return res;
});

const axiosUnauth = axios.create({
  baseURL,
  headers: {
    'content-type': 'application/json',
  },
});

export { axiosUnauth };

export default instance;

export const formDataCreator = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};
