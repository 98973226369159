import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { introContent } from 'data/introData';
import { PortalLogo } from 'components/BaseComponents/Icons';
import IntroContent from 'components/SharedComponents/IntroContent/IntroContent';

import { useStyles, LogoWrapper } from './signUpIntroduction.style';

export default ({ accountType }) => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth='sm'>
        <LogoWrapper>
          <PortalLogo />
        </LogoWrapper>
        <Typography component='h1' variant='h1' className={classes.title}>
          {introContent[accountType].title}
        </Typography>
        <IntroContent
          title={introContent[accountType].subtitle}
          list={introContent[accountType].benefits}
          classNames={{ title: classes.subtitle, textWrapper: classes.textWrapper }}
        />
      </Container>
    </>
  );
};
