import { RoutesNames, TabValues } from '../../RoutesNames';

export const getPathname = (category) => {
  switch (category) {
    case 'COMPLAINT':
      return TabValues[RoutesNames.Violations];
    case 'CONTRACT':
      return TabValues[RoutesNames.Contracts];
    case 'SUBSCRIPTION':
      return TabValues[RoutesNames.Payment];
    case 'APPLICATION':
      return TabValues[RoutesNames.Requests];
    default:
      return '';
  }
};
