import React, { useReducer, createContext } from 'react';
import useCheckContext from '../components/Hooks/useCheckContext';

const initialState = {
  loginError: '',
  username: '',
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR': {
      return {
        ...state,
        loginError: action.payload.error,
        username: action.payload.username,
        loading: false,
      };
    }
    case 'CLEAR_ERROR': {
      return {
        ...state,
        loginError: '',
        username: '',
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'CLEAR_LOADING': {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = [state, dispatch];
  return <LoginContext.Provider value={value}> {children} </LoginContext.Provider>;
};

export const useLoginContext = () => {
  return useCheckContext(LoginContext, 'LoginProvider must be used on higher level');
};
