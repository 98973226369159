import React from 'react';
import Radio from '@material-ui/core/Radio';

import { useStyles } from './radio.style';

const ModifiedRadio = ({ ...props }) => {
  const classes = useStyles(props);
  return <Radio {...props} className={classes.root} />;
};

export default ModifiedRadio;
