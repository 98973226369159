import React from 'react';

import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { useAppState } from 'contexts/PropertyEditContext';
import { HeaderLogo } from 'components/BaseComponents/Icons';

import Close from 'assets/images/closeBlack.svg';
import { useStyles, LogoWrapper, Avatar, AccountWrapper, CloseIcon } from '../sidbar.style';

export const Header = ({ onClose, onLogout }) => {
  const classes = useStyles();
  const { profile } = useAppState();

  const matches = useMediaQuery('(max-width:768px)');
  return (
    <LogoWrapper>
      {matches ? (
        <AccountWrapper>
          <div>
            {profile && profile.photoUrl ? (
              <Avatar src={profile.photoUrl} alt='profile pic' />
            ) : (
              <AccountCircle className={classes.headerIcons} />
            )}
            <Typography variant='h4'>{profile.fullName}</Typography>
          </div>
          <CloseIcon src={Close} alt='Close' onClick={onClose} width={32} height={32} />
        </AccountWrapper>
      ) : (
        <HeaderLogo />
      )}
    </LogoWrapper>
  );
};
