import { getS3SignedUrl } from 'api/storage';
import { downloadFile } from 'utils';
import { userRoles } from './enums';

export const downloadGeneratedDoc = async (file) => {
  if (!file) return;

  const url = await getS3SignedUrl(file);
  const response = await fetch(url);
  const blob = await response.blob();
  downloadFile(blob, file.name);
};

export const convertPolicySampleUrls = (sampleUrls) => {
  return sampleUrls?.reduce((acc, item) => ({ ...acc, [item.docType]: item.preSignedUrl }), {});
};

export const isMoreThanTwoDecimalPlaces = (value) => {
  const priceDecimalPart = value.split('.')[1];
  return priceDecimalPart?.length > 2;
};

export const getHasLandlordPermissions = (profileType) => {
  return [userRoles.LANDLORD, userRoles.PROPERTY_MANAGER].includes(profileType);
};
