import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, styled as MUIStyled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import theme from 'components/theme';
import TextField from 'components/BaseComponents/TextField';

export const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: 16,
  },
  titleSmall: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
  },
  radioBlock: {
    paddingLeft: 8,
  },
  invalidAnimalRadio: {
    color: theme.errorColor,
    '& .MuiFormLabel-root': {
      '&.Mui-focused': {
        color: theme.typography.color,
      },
    },
    '& .MuiIconButton-label': {
      color: theme.errorColor,
    },
  },
  onboardingLink: {
    fontWeight: 700,
    '& a': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.primarycolor,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0 16px',
  },
  smallErrorMessageWrapper: {
    marginTop: '-16px',
  },

  invalid: {
    color: theme.errorColor,
    '& a': {
      color: theme.errorColor,
    },
    '& .MuiCheckbox-root': {
      color: theme.errorColor,
    },
  },
  passwordFiledWrapper: {
    margin: '-8px 0 8px',
  },
}));

export const StyledLoginMessage = styled.p`
  color: ${theme.errorColor};
  display: inline;
  margin-right: 10px;
`;

export const StyledErrorMessage = styled.p`
  color: ${theme.errorColor};
  font-weight: 500;
`;

export const SFeild = MUIStyled(({ as, wrapperClassName, ...rest }) => (
  <Field
    {...rest}
    fullWidth
    noMargin
    variant='outlined'
    as={as || TextField}
    classNames={{ wrapper: wrapperClassName }}
  />
))({
  margin: 0,
  '& + p': {
    position: 'static',
    marginTop: 0,
    marginBottom: -21,
  },
});

export const STermsLink = styled(({ children, href, ...rest }) => (
  <a href={href} target='_blank' rel='noreferrer' {...rest}>
    {children}
  </a>
))`
  border-bottom: 2px solid ${theme.palette.primary.main};
  color: ${theme.primarycolor};
  &:hover {
    border-bottom: 2px solid ${theme.palette.primary.main};
    text-decoration: none;
  }
`;

export const STerm = styled(({ children, ...rest }) => <p {...rest}>{children}</p>)`
  font-weight: 500;
  @media (max-width: 960px) {
    font-weight: 400;
    font-size: 14px;
  }
`;
export const STermLabel = styled(({ ...rest }) => <FormControlLabel {...rest} />)({
  alignItems: 'flex-start',
});

export const STermCheckBox = styled(({ ...rest }) => <Field as={Checkbox} name='terms' color='primary' {...rest} />)({
  paddingTop: 2,
});
