import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'contexts/PropertyEditContext';

const RedirectToDefault = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch({ type: 'SET_LASTPOSITION', payload: `${location.pathname}${location.search || ''}` });
  }, []);

  return <Redirect to='/' />;
};

export default RedirectToDefault;
