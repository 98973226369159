export const LOGIN_TYPE = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phone',
};

export const LOGIN_FAILURE_MESSAGE = {
  'Temporary password has expired and must be reset by an administrator.':
    'Your temporary password has expired. Please try to reset your password.',
  'UserMigration failed with error Bad credentials.': 'Incorrect username or password.',
};
