import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { makeStyles, styled as MUIStyled } from '@material-ui/core/styles';

import theme from 'components/theme';
import TextField from 'components/BaseComponents/TextField';

export const useStyles = makeStyles((theme) => ({
  titleSmall: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
    marginBottom: '17px',
  },
  subtitleSmall: {
    fontWeight: 500,
    marginBottom: '24px',
  },
  onboardingLink: {
    marginBottom: theme.spacing(1),
    fontWeight: 800,
    '& a': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.primarycolor,
      '&:hover': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        textDecoration: 'none',
      },
    },
  },
  onboardingBtn: {
    marginRight: '10px',
  },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0',
  },
  registerTypeWrapper: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const StyledErrorMessage = styled.p`
  color: ${theme.errorColor};
  font-weight: 500;
  height: auto;
  margin-top: -16px;
`;

export const SFeild = MUIStyled(({ as, ...rest }) => (
  <Field fullWidth as={as || TextField} margin='normal' variant='outlined' {...rest} />
))({
  margin: '-8px 0 8px',
  '& + p': {
    top: 32,
  },
});

export const SWrapper = styled.div`
  height: 100vh;
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
