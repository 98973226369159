import { object, string, boolean, ref } from 'yup';

export const validationSchema = (registerUsing, accountType) => {
  const phoneRegExp = /^(\d{11}|\d{12})$/;

  const validationObject = {
    fullName: string().trim().required('Full name is required'),
    businessName: string()
      .trim()
      .test('businessName', 'Business name is required', (businessName) => {
        return !(accountType === 'landlord' && !businessName);
      }),
    confirm_password: string()
      .oneOf([ref('password'), null], 'Passwords mismatch')
      .required('Confirm password is required'),
    password: string().required('Password is required').min(6, 'The password should contain min. 6 characters.'),
    haveAnimal: string().test('haveAnimal', 'This field is required', (haveAnimal) => {
      if (accountType === 'landlord' || haveAnimal !== undefined) {
        return true;
      }
      return false;
    }),
    roommateHasAnimal: string().when('haveAnimal', {
      is: 'no',
      then: string().required('This field is required'),
    }),
    terms: boolean().isTrue('You should accept our T&C and Privacy Policy'),
  };

  if (registerUsing === 'phone') {
    validationObject.phone = string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone Number is required');
    delete validationObject.email;
  } else {
    validationObject.email = string().email('Invalid Email Address').required('Email is required');
    delete validationObject.phone;
  }

  if (accountType === 'landlord') {
    validationObject.phone = string().matches(phoneRegExp, 'Phone number is not valid');
  }

  return object(validationObject);
};
