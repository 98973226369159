import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  welcome: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'aesthet-nova, serif',
  },
  formPanel: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 960px)': {
      alignItems: 'flex-start',
      minHeight: 'calc(100vh - 73px)',
    },
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    '@media (max-width:960px)': {
      margin: theme.spacing(4, 2),
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%)',
    '@media (max-width:960px)': {
      top: '65%',
    },
  },
}));
