import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  fadeoutAlert: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
    '& .MuiAlert-root': {
      alignItems: 'center',
      padding: '12px 16px',
      minWidth: '312px',
      width: 'fit-content',
      position: 'fixed',
      right: '30px',
      top: '100px',
      marginLeft: '16px',
      zIndex: '1999',
      boxShadow: '0px 0px 8px 4px rgba(0, 0, 0, 0.04)',
      borderRadius: '4px',
      border: '0.5px solid',
      color: theme.primarycolor,
      '&.MuiAlert-standardSuccess': {
        background: 'rgba(237, 247, 237, 0.81)',
        borderColor: theme.successColor,
      },
      '&.MuiAlert-standardError': {
        background: 'rgba(255, 235, 235, 0.81)',
        borderColor: '#FF8282',
      },
      '& .MuiAlert-action': {
        backgroundColor: '#ffffff',
        borderRadius: '50%',
        justifyContent: 'center',
        color: '#0d0d0d',
        padding: '7px',
        maxHeight: '25px',
        maxWidth: '25px',
        visibility: 'hidden',
        '& .MuiIconButton-root:hover': {
          backgroundColor: '#ffffff',
        },
      },
      '& .MuiAlert-message': {
        paddingRight: '14px',
      },
      '&:hover .MuiAlert-action': {
        visibility: 'visible',
      },
    },
  },
  fixedAlert: {
    width: ({ fullWidth }) => (fullWidth ? '100%' : ''),
    '& .MuiPaper-root': {
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 0px 11px rgba(0, 0, 0, 0.1)',
      alignItems: 'center',
      '&.MuiAlert-standardWarning': {
        backgroundColor: '#FEF3D3',
        color: '#0D0D0D',
      },
    },
  },
}));
