import React from 'react';
import clsx from 'clsx';
import Tab from '@material-ui/core/Tab';
import { useLocation, useHistory } from 'react-router-dom';

import { BottomSection, useStyles } from '../sidbar.style';

export const Footer = ({ onClose, onLogout }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const menues = [
    {
      label: 'Profile settings',
      isActive: ['/intro', '/profile'].includes(pathname),
      clickHandler: () => history.push(pathname === '/intro' ? '/intro' : '/profile'),
    },
    {
      label: 'Logout',
      clickHandler: onLogout,
    },
  ];

  return (
    <BottomSection>
      {menues.map(({ label, clickHandler, isActive }) => (
        <Tab
          key={label}
          label={label}
          className={clsx('simple-tab', isActive ? 'dashboard-tabs_active' : 'dashboard-tabs')}
          classes={{ root: classes.root }}
          onClick={() => {
            clickHandler();
            onClose();
          }}
        />
      ))}
    </BottomSection>
  );
};
