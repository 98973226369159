import axios, { axiosUnauth } from '../axios';

const requester = (options) => {
  const { unAuth = false, ...opt } = options;
  if (unAuth) {
    return axiosUnauth(opt);
  }
  return axios(opt);
};

export const singleRequestHandler = async (request, convertor) => {
  const response = await requester(request);
  if (convertor) {
    return convertor(response.data);
  }
  return response.data;
};
